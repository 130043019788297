import React, { Component } from "react";
import { asset_url, website_url, host_url, domain_name } from "../app-config";
import withAppData from "../HOC/withAppData";
import AlertIcon from "../Resources/Icons/AlertIcon";
// import { distanceInWordsStrict } from "date-fns";
import dayjs from "dayjs";
import { Link } from "react-router-dom";
import logo from "../Images/omnilogo_blue.png";
// import Avatar from "../Resources/Avatar";
import axios from "axios";
import "../Styles/Pages/header.scss";

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      animateIcon: false,
      notifications: [],
      // showNotifications: "hide",
      showNotifications: "show",
      latestNotifcation: {},
      unreadNotifications: 0,
      allNotif: ""
    };
    this.ChangeBusiness = this.ChangeBusiness.bind(this);
    this.updateNotifications = this.updateNotifications.bind(this);
    this.toggleNotifications = this.toggleNotifications.bind(this);
  }

  toggleNotifications() {
    if (this.state.allNotif === "show-notif") {
      this.setState({ allNotif: "" });
    } else {
      this.setState({ allNotif: "show-notif" });
    }
  }

  updateNotifications(e, notif) {
    if (e) {
      e.preventDefault();
    }

    let header = this.props.context.jsonHeader;
    let data = {
      ReadStatus: true
    };
    if (!notif) {
      data.BusinessId = Number(header.headers.BusinessId);
      data.Flag = 4;
    } else {
      data.NotificationID = notif.Notificationid;
    }

    axios
      .post(`${host_url}dashboard/UpdatePushNotificationStatus`, data, header)
      .then(response => {
        if (response.data.StatusCode === 1) {
          if (notif) {
            setTimeout(() => {
              window.location.assign(notif.LandingURL);
            }, 500);
          }
        } else {
          if (notif) {
            return window.location.assign(notif.LandingURL);
          }
        }
        this.markNotificationsAsRead(notif);
      })
      .catch(error => {
        if (notif) {
          return window.location.assign(notif.LandingURL);
        }
        this.markNotificationsAsRead(notif);
      });
  }

  markNotificationsAsRead = notif => {
    let notifications = this.props.context.pushMessages;
    if (notif) {
      notifications.forEach((pushnotif, i) => {
        if (pushnotif.Notificationid === notif.Notificationid) {
          pushnotif.read = true;
          pushnotif.IsReadDetail = true;
        }
      });
    } else {
      notifications.forEach((pushnotif, i) => {
        pushnotif.read = true;
        pushnotif.IsReadDetail = true;
      });
    }
    this.props.context.getPushMessages(notifications);
  };

  renderNotifications() {
    let notifications;
    if (this.props.context.pushMessages.length === 0) {
      notifications = (
        <div className="notif-body-item no-notif">
          You do not have a notification
        </div>
      );
    } else {
      notifications = this.props.context.pushMessages
        .slice(0, 10)
        .map((notif, i) => {
          return (
            <a
              key={i}
              className={`${notif.read} notif-body-item`}
              href={notif.deepLink}
              data-link={notif.deepLink}
              data-id={notif.messageId}
              onClick={e => this.updateNotifications(e, notif)}
            >
              <div className="notif-body-item-title">{notif.title}</div>
              <div className="notif-body-item-body">{notif.body}</div>
              <div className="notif-body-item-time">
                {dayjs(new Date()).diff(dayjs(notif.time), "day") === 0
                  ? dayjs(notif.time).format("h:mm a")
                  : dayjs(new Date()).diff(dayjs(notif.time), "day") < 7
                  ? dayjs(notif.time).format("ddd")
                  : dayjs(notif.time).format("MMM D")}
              </div>
            </a>
          );
        });
    }

    return notifications;
  }

  showMenuSidebar() {
    document.querySelector(".bo-body-sidebar").classList.add("anim-sidebar");
    document.querySelector(".App").classList.add("sidebar-open");
  }

  componentDidMount() {
    if ("Notification" in window) {
      if (window.Notification.permission === "granted") {
        this.setState({ showNotifications: "show" });
      }
    }
    // Toggle sidebar menu on mobile

    let Sidebar = document.querySelector(".bo-sidebar");
    let mobileSidebar = document.querySelector(".bo-body-sidebar");
    let notifIcon = document.querySelector(".bo-header-notif");
    window.onclick = event => {
      if (event.target === Sidebar) {
        mobileSidebar.classList.remove("anim-sidebar");
        document.querySelector(".App").classList.remove("sidebar-open");
      }

      if (notifIcon) {
        if (event.target === notifIcon || notifIcon.contains(event.target)) {
          if (window.screen.width > 1014) {
            this.toggleNotifications();
          } else {
            this.props.context.history.push("/notifications");
          }
        } else {
          // this.toggleNotifications();
          this.setState({ allNotif: "" });
        }
      }
    };
  }

  ChangeBusiness(event) {
    let newBusinessId = event.target.value;
    let jsonHeader = this.props.context.jsonHeader;
    jsonHeader.headers.BusinessId = newBusinessId;
    this.props.context.updateHeaders(jsonHeader);
  }

  render() {
    const unreadNotifications = this.props.context.pushMessages.filter(
      el => !el.read
    );
    return (
      <div className="bo-header">
        <div className="bo-header-left">
          <div className="bo-header-logo">
            <div
              className="bo-header-mobile-menu"
              onClick={this.showMenuSidebar.bind(this)}
            >
              <img src="Images/menu.svg" alt="" />
            </div>
            <img
              src={logo}
              alt=""
              className="logo show--desktop"
              onClick={() => {
                window.location.href = window.location.origin + "/#/";
              }}
            />
            <span className="page-title hide--desktop">
              {this.props.context.pageTitle}
            </span>
          </div>
          <div className="bo-header-status hide">
            Subscription:{" "}
            <span className="the-status">
              {Number(this.props.context.HeaderData.IsBusinessFree) === 1
                ? "Free"
                : this.props.context.HeaderData.PlanName}
            </span>
          </div>
        </div>
        <div className="bo-header-right">
          {this.props.context.walletAmount.WalletExists === 1 ? (
            <div className="bo-header-balance hide">
              <div className="bo-wallet-balance">Wallet Balance</div>
              <div className="bo-wallet-balance-value">
                <a
                  target="_blank"
                  href={`${website_url}cpenquiry/wallet`}
                  rel="noopener noreferrer"
                >
                  {" "}
                  ₦{this.props.context.walletAmount.AmountFormated}
                </a>
              </div>
            </div>
          ) : (
            <div className="bo-header-balance" />
          )}
          {/*"Notification" in window &&
                window.Notification.permission === "granted" && (*/}

          <div className="bo-header-notif">
            <AlertIcon
              width="25"
              color="#015fd1"
              active={true}
              animate={unreadNotifications.length > 0}
            />
            {unreadNotifications.length > 0 &&
            this.props.context.pushMessages.length > 0 ? (
              <span className="notif-count">{unreadNotifications.length}</span>
            ) : (
              <span />
            )}
            <div
              className={`${this.state.showNotifications} ${this.state.allNotif} bo-header-notif-all`}
            >
              <div className="notif-header">
                <div>
                  <div
                    className="notif-header-close"
                    onClick={() => this.toggleNotifications()}
                  >
                    <img src="Images/dark-back.svg" alt="" />
                  </div>
                  Notifications
                </div>
                {unreadNotifications.length > 0 && (
                  <span onClick={this.updateNotifications}>
                    Mark all as read
                  </span>
                )}
              </div>
              <div className="notif-body">{this.renderNotifications()}</div>
              {this.props.context.pushMessages.length > 0 && (
                <div className="notif-see-all">
                  <Link style={{ textDecoration: "none" }} to="/notifications">
                    See All
                  </Link>
                </div>
              )}
            </div>
          </div>

          <div className="bo-header-user">
            <div className="bo-header-user-avatar">
              {this.props.context.HeaderData.userProfileImg !== undefined &&
              this.props.context.HeaderData.userProfileImg !== "" ? (
                <img
                  src={asset_url + this.props.context.HeaderData.userProfileImg}
                  alt=""
                />
              ) : (
                <div />
              )}
              {/* {this.props.context.SessionData.VCUserPhoto ? (
                         <img src={asset_url + this.props.context.SessionData.VCUserPhoto} alt="" />
                      ) : (
                         <Avatar name={this.props.context.SessionData.VCUserName} size={35} />
                      )} */}
            </div>
            <div className="bo-header-user-details">
              <div className="flex-child">
                <div className="bo-header-user-details-name">
                  {/* {this.props.context.HeaderData.UserName} */}
                  {/* {this.props.context.SessionData.VCUserName} */}
                </div>
                <div className="bo-header-user-details-service">
                  {this.props.context.HeaderData.BusinessName}
                </div>
                {/* <div className="bo-header-user-details-service">
                            {this.props.context.SessionData.VCUserName}
                         </div> */}
                <div className="bo-header-user-details-id">
                  <span className="business-id-text">Business id: </span>
                  <span className="business-id-value">
                    {this.props.context.HeaderData.BusinessId}
                  </span>
                </div>
                {/* <div className="bo-header-user-details-id" style={{ width: "auto" }}>
                            {this.props.context.HeaderData.BusinessName} (
                            {this.props.context.HeaderData.BusinessId})
                         </div> */}
              </div>
              <div className="flex-child">
                <div className="bo-header-user-details-icon">
                  <img src="Images/ic_expand.svg" alt="" />
                </div>
              </div>
              <div className="sub-menu">
                {this.props.context.BusinessOwnerIdsWithNames.length > 1 &&
                !this.props.context.isOffline ? (
                  <div className="switch-business">
                    <div className="switch-text">Switch business</div>
                    <div className="select-wrapper">
                      <select
                        name=""
                        className="select__input"
                        value={this.props.context.HeaderData.BusinessId}
                        onChange={this.ChangeBusiness}
                      >
                        {this.props.context.BusinessOwnerIdsWithNames.slice(
                          0,
                          30
                        ).map((idname, i) => (
                          <option key={i} value={idname.BusinessId}>
                            {idname.BusinessName}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                ) : this.props.context.BusinessOwnerIdsWithNames.length > 0 ? (
                  <div className="switch-business">
                    <div className="no-switch-wrapper">
                      <span>
                        {" "}
                        {
                          this.props.context.BusinessOwnerIdsWithNames[0]
                            .BusinessName
                        }
                      </span>
                    </div>
                  </div>
                ) : (
                  <span />
                )}

                <ul>
                  <li>
                    <Link to="/contact">
                      <img src="Images/dark-contact.svg" alt="" />
                      <span>Contact Us</span>
                    </Link>
                  </li>
                  {(!this.props.context.isOffline ||
                    domain_name === "localhost") && (
                    <li>
                      <span
                        className=""
                        onClick={() => this.props.context.logOut()}
                      >
                        <img src="Images/dark-logout.svg" alt="" />
                        Logout
                      </span>
                    </li>
                  )}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withAppData(Header);

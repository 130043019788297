import React, { Component } from "react";
import "../Styles/Pages/sidebaritem.scss";

class SidebarItem extends Component {
   constructor(props) {
      super(props);
      this.menuSubRef = React.createRef();
      this.state = {
         showSub: false,
         activeSubLength: []
      };
   }

   delayComp = e => {
      if (window.screen.width < 768) {
         e.preventDefault();
         let link = e.currentTarget.dataset.id;
         setTimeout(() => {
            this.props.history.push(link);
         }, 270);
      }
   };

   isMenuActive = menu => {
      let showActive;
      if (this.props.location && this.props.location.pathname.includes(menu)) {
         showActive = true;
      }
      return showActive;
   };

   handleClick = e => {
      e.preventDefault();

      if (this.props.name === "Logout") {
         this.props.context.logOut();
      } else {
         this.props.updateActiveSidebarItem(this.props.name);
         this.setState({ activeSubLength: [] });
         if (this.props.subItems.length > 0) {
            this.setState({ showSub: !this.state.showSub });
         } else {
            this.props.history.push(this.props.link);
         }
      }
   };

   isActiveSub = () => {
      if (this.props.subItems.length > 0) {
         const subItems = this.props.subItems;
         let activeSubLength = [];
         subItems.forEach(el => {
            if (this.props.location.pathname.toLowerCase().includes(el.link.toLowerCase())) {
               activeSubLength.push(1);
            }
         });
         this.setState({ activeSubLength }, () => {
            if (this.state.activeSubLength.length > 0) {
               this.setState({ showSub: true }, () => {
                  this.props.updateActiveSidebarItem(this.props.name);
               });
            }
         });
      } else {
         if (this.props.location.pathname.toLowerCase().includes(this.props.link.toLowerCase())) {
            this.props.updateActiveSidebarItem(this.props.name);
         }
      }
   };

   componentDidMount() {
      this.isActiveSub();
   }

   componentDidUpdate = prevProps => {
      if (this.props.activeSidebarItem !== prevProps.activeSidebarItem) {
         if (this.props.activeSidebarItem !== this.props.name) {
            this.setState({ showSub: false, activeSubLength: [] });
         }
      }
      if (prevProps.location !== this.props.location) {
         this.isActiveSub();
      }
   };

   render() {
      const Icon = this.props.icon;
      return (
         <li
            className={`sidebar-list-item ${this.props.isMobile ? "hide--desktop" : ""} ${
               this.props.hide ? "hide" : ""
            }`}>
            <a
               data-id={this.props.link}
               onClick={e => this.handleClick(e)}
               className={
                  this.props.activeSidebarItem === this.props.name || this.state.activeSubLength.length > 0
                     ? "active"
                     : ""
               }
               href={`#${this.props.link}`}>
               <div>
                  <Icon
                     color={this.props.activeSidebarItem === this.props.name ? "#015fd1" : "#5f6368"}
                     height={"27px"}
                     width={"27px"}
                  />
                  <span className="sidebar-list-item-name">{this.props.name}</span>
                  {this.props.subItems.length > 0 && (
                     <span className="sidebar-list-item-toggle">
                        <img
                           src="Images/down-arrow.svg"
                           alt=""
                           style={{
                              transform: this.state.showSub ? "rotate(180deg)" : ""
                           }}
                        />
                     </span>
                  )}
               </div>
            </a>
            {/* <NavLink
               data-id={this.props.link}
               exact={true}
               activeClassName="active"
               to={this.props.link}
               isActive={() => this.isMenuActive(this.props.link)}>
               <div>
                  <Icon
                     color={this.props.activeSidebarItem === this.props.name ? "#015fd1" : "#5f6368"}
                     height={"27px"}
                     width={"27px"}
                  />
                  <span className="sidebar-list-item-name">{this.props.name}</span>
               </div>
            </NavLink> */}

            {this.state.showSub &&
               this.props.subItems.length > 0 &&
               this.props.activeSidebarItem === this.props.name && (
                  <div className="sidebar-list-item-sub" ref={this.menuSubRef}>
                     {this.props.subItems.map((el, i) => (
                        <a
                           key={i}
                           className={`sidebar-list-item-sub-link ${
                              !el.isFullUrl &&
                              this.props.location &&
                              this.props.location.pathname.includes(el.link.split("/")[1])
                                 ? "active-sub"
                                 : ""
                           } ${
                              el.isFullUrl && this.props.location && this.props.location.pathname === el.link
                                 ? "active-sub"
                                 : ""
                           } ${el.hide ? "hide" : ""}`}
                           onClick={e => {
                              e.preventDefault();
                              el.extUrl ? window.open(el.extUrl, "_blank") : this.props.history.push(el.link);
                           }}
                           href={`#${el.link}`}>
                           {el.name}
                        </a>
                     ))}
                  </div>
               )}
         </li>
      );
   }
}

export default SidebarItem;

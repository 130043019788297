import React from "react";
import axios from "axios";
import { host_url } from "./app-config";
import * as Sentry from "@sentry/browser";
import withAppData from "./HOC/withAppData";
import "./Styles/Pages/jserror.scss";

class ErrorReport extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: false,
      errorInfo: null,
      loading: "",
      errorMsg: "",
      eventId: null
    };

    this.refreshApp = this.refreshApp.bind(this);
  }

  get_browser_info() {
    if (window.navigator) {
      let ua = window.navigator.userAgent,
        tem,
        M =
          ua.match(
            /(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i
          ) || [];
      if (/trident/i.test(M[1])) {
        tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
        return { name: "IE ", version: tem[1] || "" };
      }
      if (M[1] === "Chrome") {
        tem = ua.match(/\bOPR\/(\d+)/);
        if (tem != null) {
          return { name: "Opera", version: tem[1] };
        }
      }
      M = M[2]
        ? [M[1], M[2]]
        : [window.navigator.appName, window.navigator.appVersion, "-?"];
      if ((tem = ua.match(/version\/(\d+)/i)) != null) {
        M.splice(1, 1, tem[1]);
      }
      return {
        name: M[0],
        version: M[1]
      };
    }
  }

  refreshApp() {
    this.setState({ loading: "is-loading" });
    let loadingPage = window.location.origin + "/#/loading";
    window.location.href = loadingPage;
    setTimeout(() => {
      window.location.href = window.location.origin;
    }, 3000);
  }

  logError(errorMsg) {
    var browser = this.get_browser_info();
    let reportHeader = {
      headers: {
        "content-type": "application/json",
        source: "PWA"
      }
    };
    let reportData = {
      AppHttpStatus: "",
      AppErrorMsg: errorMsg,
      AppUrl: window.location.href,
      AppErrorStack: "",
      userId: this.props.context.SessionData.VCUserContentID,
      userName: "",
      source: "",
      Browser: browser.name,
      Os: browser.version
    };
    // debugger;
    if (window.location.href.includes("localhost")) {
    } else {
      axios
        .post(`${host_url}Logger/SaveLogJsException`, reportData, reportHeader)
        .then(response => {
          if (response.data.StatusCode === 1) {
          }
        })
        .catch(error => {});
    }
  }
  componentDidCatch(error, errorInfo) {
    this.setState(
      {
        error: error,
        errorInfo: errorInfo,
        errorMsg: error.toString()
      },
      () => {
        this.logError(error.toString());
      }
    );
    if (window.location.hostname !== "localhost") {
      Sentry.withScope(scope => {
        scope.setExtras(errorInfo);
        scope.setTags({
          userId: this.props.context.SessionData.VCUserContentID,
          appVersion: this.props.context.appVersion.current || ""
        });
        const eventId = Sentry.captureException(error);
        this.setState({ eventId });
      });
    }
  }

  render() {
    if (this.state.error) {
      return (
        <div>
          <div className="bo-header-content" />
          <div className="bo-body">
            <div className="bo-body-sidebar" />
            <div className="bo-body-content">
              <div className="js-error-content">
                <div className="js-error-panel panel">
                  <div className="js-error-panel-icon">
                    {/* <img src ="Images/js-error.svg" alt =""/> */}
                    <div
                      className="cross-icon cross-delete animateDeleteIcon"
                      style={{ display: "block" }}
                    >
                      <span className="cross-x-mark animateXMark" />
                      <span className="cross-delete-line cross-delete-left" />
                      <span className="cross-delete-line cross-delete-right" />
                    </div>
                  </div>
                  <div className="js-error-panel-header">
                    An error occurred while trying to open the app
                  </div>
                  <div className="js-error-panel-button">
                    <button
                      className={`${this.state.loading} btn btn--primary`}
                      onClick={this.refreshApp.bind(this)}
                    >
                      Refresh
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }

    return this.props.children;
  }
}

export default withAppData(ErrorReport);

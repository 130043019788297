import React, { Fragment } from "react";

const SyncOfflineData = props => (
  <div className="update-notification-cont show-update-notification">
    <div className="update-notification">
      {props.error ? (
        <Fragment>
          <div className="update-notification-icon is-error">
            <img src="Images/sync-error.svg" alt="" />
          </div>
          <div className="update-notification-text">
            <div className="update-notification-text-top">An error occured while syncing</div>
            <div className="update-notification-text-bottom">
              <span className="">Your data will be resynced soon</span>
            </div>
          </div>
        </Fragment>
      ) : (
        <Fragment>
          {!props.syncing ? (
            <div className="update-notification-icon">
              <img src="Images/check_wt.svg" alt="" style={{ height: "17px" }} />
            </div>
          ) : (
            <div className="update-notification-icon">
              <img src="Images/sync.svg" alt="" className="rotate-icon" />
            </div>
          )}
          {!props.syncing ? (
            <div className="update-notification-text" onClick={() => window.location.reload()} style={{ cursor: "pointer" }}>
              <div className="update-notification-text-top">Data synced successfully</div>
              <div className="update-notification-text-bottom">
                <span className="">Click here to refresh your app</span>
              </div>
            </div>
          ) : (
            <div className="update-notification-text">
              <div className="update-notification-text-top">You are back online</div>
              <div className="update-notification-text-bottom">
                <span className="">Please wait while we sync your data</span>
              </div>
            </div>
          )}
        </Fragment>
      )}

      <div className="update-notification-close" onClick={() => props.close()}>
        <img src="Images/close-icon.svg" alt="" />
      </div>
    </div>
  </div>
);

export default SyncOfflineData;

import React from "react";

class ModalLoading extends React.Component {
  render() {
    return (
      <div className="loading-container" style = {{position: "fixed"}}>
        
      </div>
    );
  }
}

export default ModalLoading;

import React from "react";
import "babel-polyfill";
import ReactDOM from "react-dom";
import { HashRouter } from "react-router-dom";
import * as Sentry from "@sentry/browser";
import "./index.scss";
import App from "./App";
import registerServiceWorker from "./registerServiceWorker";
import OldBrowser from "./OldBrowser";

if (window.location.hostname !== "localhost") {
  Sentry.init({
    dsn: "https://8d4ae1dd85ef4361b45ff509b2e56609@sentry.io/1859398",
    environment: window.location.hostname
  });
}

ReactDOM.render(
  navigator.userAgent && navigator.userAgent.indexOf("Opera Mini") > -1 ? (
    <OldBrowser />
  ) : (
    <HashRouter>
      <App />
    </HashRouter>
  ),
  document.getElementById("root")
);

registerServiceWorker();

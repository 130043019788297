import React, { Component } from "react";

class Avatar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      bgColor: "rgba(1, 95, 209, 0.07)"
    };
  }

  componentDidMount() {
    if (this.props.isRandomBg && this.props.name) {
      // let letters = "0123456789ABCDEF".split("");
      // let color = "#";
      // for (let i = 0; i < 6; i++) {
      //   color += letters[Math.round(Math.random() * 10)];
      // }
      // this.setState({ bgColor: color });

      let hash = 0;
      for (let i = 0; i < this.props.name.length; i++) {
        hash = this.props.name.charCodeAt(i) + ((hash << 5) - hash);
      }
      let h = hash % 360;
      this.setState({ bgColor: "hsl(" + h + ", " + 30 + "%, " + 80 + "%)" });
    }
  }

  render() {
    const props = this.props;
    return (
      <div
        className="avatar-placeholder"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          color: props.color || "#232f49",
          borderRadius: "50%",
          fontSize: props.fontSize || "12px",
          background: props.background || this.state.bgColor,
          width: `${props.size}px`,
          height: `${props.size}px`
        }}
      >
        <span>{props.name ? props.name.substring(0, 1).toUpperCase() : ""}</span>
      </div>
    );
  }
}

export default Avatar;

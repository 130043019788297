import dayjs from "dayjs";
export const _filterArray = (array, id, param) => {
  return array.filter(el => {
    return (
      (el[id]
        ? typeof el[id] === "number"
          ? el[id]
          : el[id].toLowerCase().replace(/ /g, "")
        : el[id]) !==
      (param
        ? typeof param === "number"
          ? param
          : param.toLowerCase().replace(/ /g, "")
        : param)
    );
  });
};

export const _isInViewPort = el => {
  let rect = el.getBoundingClientRect();
  return (
    rect.top >= 0 &&
    rect.left >= 0 &&
    rect.bottom <=
      (window.innerHeight ||
        document.documentElement.clientHeight) /*or $(window).height() */ &&
    rect.right <=
      (window.innerWidth ||
        document.documentElement.clientWidth) /*or $(window).width() */
  );
};

export const _generateColor = () => {
  let letters = "0123456789ABCDEF".split("");
  let color = "#";
  for (let i = 0; i < 6; i++) {
    color += letters[Math.round(Math.random() * 10)];
  }

  return color;
};

export const _findArray = (array, id, param) => {
  return array.find(el => {
    return (
      (el[id]
        ? typeof el[id] !== "string"
          ? el[id]
          : el[id].toLowerCase().replace(/ /g, "")
        : el[id]) ===
      (param
        ? typeof param !== "string"
          ? param
          : param.toLowerCase().replace(/ /g, "")
        : param)
    );
  });
};

export const _isLightColor = color => {
  // Variables for red, green, blue values
  let r, g, b, hsp;
  // Check the format of the color, HEX or RGB?
  if (color.match(/^rgb/)) {
    // If HEX --> store the red, green, blue values in separate variables
    color = color.match(
      /^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/
    );
    r = color[1];
    g = color[2];
    b = color[3];
  } else {
    // If RGB --> Convert it to HEX
    color = +("0x" + color.slice(1).replace(color.length < 5 && /./g, "$&$&"));
    r = color >> 16;
    g = (color >> 8) & 255;
    b = color & 255;
  }
  // HSP equation
  hsp = Math.sqrt(0.299 * (r * r) + 0.587 * (g * g) + 0.114 * (b * b));

  // Using the HSP value, determine whether the color is light or dark
  return hsp > 210;
};

export const _hex2rgba = (hex, alpha = 1) => {
  const [r, g, b] = hex.match(/\w\w/g).map(x => parseInt(x, 16));
  return `rgba(${r},${g},${b},${alpha})`;
};

export const _truncText = (text, limit) => {
  return text && limit
    ? text.length > limit
      ? `${text.substring(0, limit)}...`
      : text
    : text;
};

export const _uniqueArray = (array, param, type) => {
  if (type === "single") {
    return Array.from(new Set(array));
  } else {
    return Object.values(
      array.reduce((acc, cur) => Object.assign(acc, { [cur[param]]: cur }), {})
    );
  }
};

export const _convertToSelectArray = (array, name, value) => {
  return array.forEach(el => {
    el.name = el[name] || "";
    el.label = el[name] || "";
    el.value = el[value] || "";
  });
};

export const _appendSelectAll = (array, text) => {
  array.length > 0 && array.unshift({ name: text || "All", value: null });
  return array;
};

export const _sortArray = (array, param, desc) => {
  return array.sort(compareValues(param, desc ? "desc" : "asc"));
};

export const _removeStringSpace = string => {
  return string ? string.replace(/ /g, "") : "";
};

export const _getNumberOrdinal = number => {
  let s = ["th", "st", "nd", "rd"];
  let v = number % 100;
  return number + (s[(v - 20) % 10] || s[v] || s[0]);
};

export const _pluraliseString = (string, count) => {
  return count > 1 ? `${string}s` : string;
};

export const _validateNumber = (event, allowFloat) => {
  let key;
  if (event.type === "paste") {
    key = event.clipboardData.getData("text/plain");
  } else {
    key = event.keyCode || event.which;
    key = String.fromCharCode(key);
  }
  let regex = /^\d+$/;

  if (!regex.test(key)) {
    if (allowFloat) {
      if (key !== ".") {
        event.returnValue = false;
        if (event.preventDefault) event.preventDefault();
      }
    } else {
      event.returnValue = false;
      if (event.preventDefault) event.preventDefault();
    }
  }
};

export const _validatePhoneNumber = (phoneNumber, countryCode = 1) => {
  let maxLength, minLength;
  if (countryCode === 1) {
    maxLength = 11;
    minLength = 11;
  }
  if (phoneNumber) {
    if (/^\d+$/.test(phoneNumber)) {
      phoneNumber = phoneNumber.toString();
      if (Number(phoneNumber.substring(0, 1)) === 0) {
        if (
          phoneNumber.length !== 0 &&
          (phoneNumber.length > maxLength || phoneNumber.length < minLength)
        ) {
          return `Phone numbers should have a ${
            phoneNumber.length > maxLength
              ? `maximum of ${maxLength}`
              : `minimum of ${maxLength}`
          } numbers`;
        } else {
          return "";
        }
      } else {
        return `Phone numbers should start with 0`;
      }
    } else {
      return `Invalid phone number`;
    }
  } else {
    return "";
  }
};

export const _getLocationDistances = (lat1, lon1, lat2, lon2) => {
  if (lat1 && lat2 && lon1 && lon2) {
    let p = 0.017453292519943295; // Math.PI / 180
    let c = Math.cos;
    let a =
      0.5 -
      c((lat2 - lat1) * p) / 2 +
      (c(lat1 * p) * c(lat2 * p) * (1 - c((lon2 - lon1) * p))) / 2;
    let distance = 12742 * Math.asin(Math.sqrt(a));

    return distance > 1
      ? parseFloat(Number.parseFloat(distance).toFixed(1)) + " km"
      : parseFloat(Number.parseFloat(distance * 1000).toFixed(1)) + " m";
  } else {
    return "";
  }
};

export const _capitalizeText = string => {
  return string
    ? string
        .toLowerCase()
        .split(" ")
        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
        .join(" ")
    : "";
};

export const _compressImage = (file, size, name) => {
  return new Promise((resolve, reject) => {
    let updatedFile;
    const img = new Image();
    const imgUrl = window.URL.createObjectURL(file);
    img.onload = () => {
      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");
      const width = size || 250;
      const scaleFactor = width / img.width;
      canvas.width = width;
      canvas.height = img.height * scaleFactor;
      ctx.drawImage(img, 0, 0, width, img.height * scaleFactor);
      ctx.canvas.toBlob(
        blob => {
          updatedFile = new File([blob], name || "imgupload.jpg", {
            type: "image/jpeg",
            lastModified: Date.now()
          });
          resolve(updatedFile);
        },
        "image/jpeg",
        1
      );
    };
    img.src = imgUrl;
  });
};

export const _isSameDate = (firstDate, secondDate) => {
  return firstDate &&
    secondDate &&
    dayjs(firstDate).isValid() &&
    dayjs(secondDate).isValid()
    ? firstDate.getFullYear() === secondDate.getFullYear() &&
        firstDate.getMonth() === secondDate.getMonth() &&
        firstDate.getDate() === secondDate.getDate()
    : false;
};
export const _formatDate = date => {
  return date && dayjs(date).isValid() ? dayjs(date).format("YYYY-MM-DD") : "";
};

export const _formatTime = date => {
  return date && dayjs(date).isValid() ? dayjs(date).format("HH:mm") : "";
};

export const _getTimeDifference = (
  firstDate,
  secondDate,
  unit,
  roundUp = false
) => {
  if (
    firstDate &&
    secondDate &&
    unit &&
    dayjs(firstDate).isValid() &&
    dayjs(secondDate).isValid()
  ) {
    firstDate = dayjs(firstDate);
    secondDate = dayjs(secondDate);
    return firstDate.diff(secondDate, unit, roundUp);
  } else {
    return 0;
  }
};

export const _cloneArray = array => {
  if (array) {
    return JSON.parse(JSON.stringify(array));
  }
};

export const _combineDateAndTime = (date, time, showYear, timeOnly) => {
  if (date && !dayjs(date).isValid()) {
    date = this.reverseDate(date);
  }
  if (date && time) {
    if (time.includes(":")) {
      if (timeOnly) {
        return dayjs(
          dayjs(date).add(
            Number(time.split(":")[0]) * 60 + Number(time.split(":")[1]),
            "minutes"
          )
        ).format("h:mm a");
      } else {
        return (
          (showYear
            ? dayjs(date).format("MMM D, YYYY")
            : dayjs(date).format("MMM D")) +
          ". " +
          dayjs(
            dayjs(date).add(
              Number(time.split(":")[0]) * 60 + Number(time.split(":")[1]),
              "minutes"
            )
          ).format("h:mm a")
        );
      }
    }
  } else if (date) {
    return showYear
      ? dayjs(date).format("MMM D, YYYY")
      : dayjs(date).format("MMM D");
  } else return "";
};

function compareValues(key, order = "asc") {
  return function innerSort(a, b) {
    if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) {
      return 0;
    }
    if (a[key] === "" || a[key] === null || a[key] === undefined) {
      return 1;
    }
    if (b[key] === "" || b[key] === null || b[key] === undefined) {
      return -1;
    }

    const varA = typeof a[key] === "string" ? a[key].toUpperCase() : a[key];
    const varB = typeof b[key] === "string" ? b[key].toUpperCase() : b[key];

    let comparison = 0;
    if (varA > varB) {
      comparison = 1;
    } else if (varA < varB) {
      comparison = -1;
    }
    return order === "desc" ? comparison * -1 : comparison;
  };
}
export const _isPushNotificationsSupported = () => {
  return (
    "Notification" in window &&
    navigator.serviceWorker &&
    "PushManager" in window &&
    "showNotification" in ServiceWorkerRegistration.prototype
  );
};

export const _insertInString = (
  stringToSearch,
  stringToFind,
  stringToInsert
) => {
  if (stringToSearch && stringToFind && stringToInsert) {
    const index = stringToSearch.lastIndexOf(stringToFind);
    if (index < 0) {
      return stringToSearch;
    } else {
      return (
        stringToSearch.substring(0, index) +
        stringToInsert +
        stringToSearch.substring(index)
      );
    }
  } else {
    return stringToSearch;
  }
};

export const _isViewingInApp = () => {
  if (window.matchMedia) {
    return window.matchMedia("(display-mode: standalone)").matches;
  } else return false;
};

export const _getIsoDateString = currentDate => {
  const date = currentDate || new Date();
  return new Date(
    date.getTime() - date.getTimezoneOffset() * 60000
  ).toISOString();
};

export const _cloneObject = object => {
  if (object) {
    return JSON.parse(JSON.stringify(object));
  }
};
export const _fetchFile = async url => {
  let response = await fetch(`https://cors-anywhere.herokuapp.com/${url}`);
  let blob = await response.blob();
  let file = new File([blob], `image-${Math.random()}.jpg`);
  return file;
};

export const _uniqueArrayCount = (array, key) => {
  let count = new Set();
  for (let el of array) {
    count.add(el[key]);
  }

  return count.size;
};

export const _groupArray = (array, field) => {
  return array.reduce(function(h, obj) {
    h[obj[field]] = (h[obj[field]] || []).concat(obj);
    return h;
  }, {});
};

import React, { useEffect, useState } from "react";
import { _isViewingInApp } from "../../Utils/helpers";

const UpdateAppNotification = props => {
  const [show, setShow] = useState("");
  useEffect(() => {
    setTimeout(() => {
      setShow("show-update-notification");
    }, 1000);
  }, []);

  return (
    <div className={`update-notification-cont ${show}`}>
      <div className="update-notification">
        <div
          className="update-notification-icon"
          onClick={() => props.update()}
        >
          <img src="Images/refresh.svg" alt="" />
        </div>
        <div
          className="update-notification-text"
          onClick={() => props.update()}
        >
          <div className="update-notification-text-top">
            {_isViewingInApp()
              ? "A new version of the app is available"
              : "A new version is available"}
          </div>
          <div
            className="update-notification-text-bottom"
            onClick={() => props.update()}
          >
            <span>Click here to update</span>
          </div>
        </div>
        <div
          className="update-notification-close"
          onClick={() => props.close()}
        >
          <img src="Images/close-icon.svg" alt="" />
        </div>
      </div>
    </div>
  );
};

export default UpdateAppNotification;

import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { host_url, asset_url } from "../app-config";
import withAppData from "../HOC/withAppData";
import {
  DashboardIcon,
  LeadsIcon,
  ProfileIcon,
  LogoutIcon,
  ReportIcon,
  ContactIcon,
  ProductsIcon,
  AccountsIcon,
  TeamIcon
} from "../Resources/Icons/Collections";
import SettingsIcon from "../Resources/Icons/SettingsIcon";
import Avatar from "../Resources/Avatar";
import axios from "axios";
import SidebarItem from "./SidebarItem";
import { _truncText } from "../Utils/helpers";
import "../Styles/Pages/sidebar.scss";

class Sidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      otherBusiness: "hide",
      imageSwitch: "no-transform",
      sidebarItems: [],
      activeSidebarItem: ""
    };

    this.showNewLeads = this.showNewLeads.bind(this);

    this.showDownload = this.showDownload.bind(this);
    this.hideSidebarMenu = this.hideSidebarMenu.bind(this);
    this.fireCleverTapEvent = this.fireCleverTapEvent.bind(this);
  }

  hideSidebarMenu() {
    document.querySelector(".bo-body-sidebar").classList.remove("anim-sidebar");
    document.querySelector(".App").classList.remove("sidebar-open");
  }

  renderDownloadLink() {
    let downloadLink;
    if (
      window.screen.width < 768 &&
      window.matchMedia &&
      this.props.context.showDownloadMenu &&
      !this.props.context.isAppInstalled
    ) {
      if (!window.matchMedia("(display-mode: standalone)").matches) {
        downloadLink = (
          <div className="install-prompt-buttons" onClick={this.showDownload}>
            <button className="btn">
              <span>
                <img src="Images/download.svg" alt="" />
                Download
              </span>
            </button>
          </div>
        );
      }
    }
    return downloadLink;
  }

  showDownload() {
    document.querySelector(".bo-body-sidebar").classList.remove("anim-sidebar");
    document.querySelector(".App").classList.remove("sidebar-open");

    let clevertapData = {
      d: [
        {
          identity: this.props.context.HeaderData.BusinessId,
          type: "event",
          evtName: "BOPWaMenuDownloadClick",
          evtData: {}
        }
      ]
    };
    axios
      .post(
        `${host_url}Logger/SendClaverTapEvent`,
        clevertapData,
        this.props.context.clevertapHeader
      )
      .then(response => {})
      .catch(error => {});
    this.props.context.downloadApp();
  }

  showNewLeads() {
    if (window.screen.width > 1014) {
      return;
    }
    let leadData = {
      filter: 1,
      unAcceptedLead: this.props.context.leads.unAcceptedLead,
      unAcceptedMarket: this.props.context.leads.unAcceptedMarket,
      marketCount: this.props.context.leads.marketCount,
      marketParams: this.props.context.leads.marketParams
    };
    this.props.context.updateLeads(leadData);
  }

  toggleOtherBusiness() {
    if (this.props.context.BusinessOwnerIdsWithNames.length > 1) {
      if (
        this.state.otherBusiness === "hide" &&
        this.state.imageSwitch === "no-transform"
      ) {
        this.setState({ otherBusiness: "show" }, () =>
          this.setState({ imageSwitch: "transform-img" })
        );
      } else {
        this.setState({ otherBusiness: "hide" }, () =>
          this.setState({ imageSwitch: "no-transform" })
        );
      }
    }
  }

  fireCleverTapEvent() {
    let clevertapData = {
      d: [
        {
          identity: this.props.context.jsonHeader.headers.BusinessId,
          type: "event",
          evtName: "BOPWAProfilePagVisit",
          evtData: {}
        }
      ]
    };
    axios
      .post(
        `${host_url}Logger/SendClaverTapEvent`,
        clevertapData,
        this.props.context.clevertapHeader
      )
      .then(response => {})
      .catch(error => {});
  }

  changeBusiness(event) {
    document.querySelector(".bo-body-sidebar").classList.remove("anim-sidebar");
    document.querySelector(".App").classList.remove("sidebar-open");
    let newBusinessId = event.currentTarget.dataset.id;
    let jsonHeader = this.props.context.jsonHeader;
    jsonHeader.headers.BusinessId = newBusinessId;
    this.props.context.updateHeaders(jsonHeader);
  }

  updateActiveSidebarItem = activeSidebarItem => {
    this.setState({ activeSidebarItem }, () => {});
  };

  checkBranch = () => {
    const BusinessId = this.props.context.HeaderData.BusinessId;
    const Businesses = this.props.context.HeaderData.BusinessOwnerIdsWithNames;
    Businesses.forEach(element => {
      if (element.BusinessId === BusinessId && element.BranchCt > 0) {
        return true;
      }
      return false;
    });
  };

  componentDidUpdate(prevProps) {
    if (this.props.location) {
      if (this.props.location.pathname) {
        if (prevProps.location.pathname !== this.props.location.pathname) {
          document
            .querySelector(".bo-body-sidebar")
            .classList.remove("anim-sidebar");
          document.querySelector(".App").classList.remove("sidebar-open");
        }
      }
    }
  }

  render() {
    const isDefaultSidebar =
      Number(this.props.context.SessionData.isAgent) === 1;
    let sidebarItems = [
      {
        link: "/",
        name: "Dashboard",
        subItems: [],
        icon: DashboardIcon
      },
      {
        moduleId: 1,
        link: "",
        name: "Presence management",
        subItems: [
          {
            link: "/profile",
            name: "Profile Details",
            subModuleId: 6,
            hide: !this.props.context.hasPermission(6, 2)
          },
          {
            link: "/keywords",
            name: "Keywords",
            subModuleId: 7,
            hide: !this.props.context.hasPermission(7, 2)
          },
          {
            link: "/listings",
            name: "Listings",
            subModuleId: 8,
            hide: !this.props.context.hasPermission(8, 2)
          },
          {
            link: "/business-website",
            name: "Website",
            subModuleId: 9,
            hide: !this.props.context.hasPermission(9, 2)
          },
          {
            link: "/posts",
            name: "Posts",
            subModuleId: 10,
            hide: !this.props.context.hasPermission(10, 2)
          },
          {
            link: "/reviews",
            name: "Reviews",
            subModuleId: 11,
            hide: !this.props.context.hasPermission(11, 2)
          }
        ],
        icon: ProfileIcon,
        hide: !this.props.context.hasPermission(1, 1)
      },
      {
        moduleId: 2,
        link: "",
        name:
          this.props.context.HeaderData.walletAmount.BusinessType === "Hospital"
            ? "Patient management"
            : "Customer management",
        subItems: [
          {
            link: "/tools/addressbook",
            name:
              this.props.context.HeaderData.walletAmount.BusinessType ===
              "Hospital"
                ? "Patients"
                : this.props.context.isSettingsActivated(19)
                ? "Contacts"
                : "Customers",
            hide: !this.props.context.hasPermission(12, 2)
          },
          {
            link: "/leads",
            name: "Enquiries",
            subModuleId: 13,
            hide: !this.props.context.hasPermission(13, 2)
          },
          {
            link: "/customer-pipeline",
            name: "Customer Pipeline",
            subModuleId: 14,
            hide: !this.props.context.hasPermission(14, 2)
          },

          {
            link:
              this.props.context.HeaderData.walletAmount.BusinessType ===
              "Hospital"
                ? "/patients-task"
                : "/calendar",
            name:
              this.props.context.HeaderData.walletAmount.BusinessType ===
              "Hospital"
                ? "Tasks"
                : "Calendar",
            hide: !this.props.context.hasPermission(103, 2)
          },
          {
            link: "/plans",
            name: "Plans",
            hide: !this.props.context.permissions.subModules.includes(114)
          },
          {
            link: "/pipeline-reports",
            name: "Report",
            subModuleId: 15,
            hide: !this.props.context.hasPermission(15, 2)
          }
        ],
        icon: LeadsIcon,
        hide: !this.props.context.hasPermission(2, 1)
      },
      {
        moduleId: 3,
        link: "",
        name: "Order management",
        subItems: [
          {
            link: "/store",
            name: "Store",
            subModuleId: 16,
            hide:
              !this.props.context.hasPermission(16, 2) ||
              ([1640093, 1630625].includes(
                this.props.context.HeaderData.BusinessId
              ) &&
                this.props.context.HeaderData.walletAmount.RoleName !== "Admin")
          },
          {
            link: "/invoices",
            name: "Sales Orders",
            subModuleId: 17,
            hide: !this.props.context.hasPermission(17, 2)
          },
          {
            link: "/stock",
            name: "Stock",
            hide:
              !this.props.context.hasPermission(101, 2) ||
              ([1640093, 1630625].includes(
                this.props.context.HeaderData.BusinessId
              ) &&
                this.props.context.HeaderData.walletAmount.RoleName !== "Admin")
          }
        ],
        icon: ProductsIcon,
        hide: !this.props.context.hasPermission(3, 1)
      },
      {
        moduleId: 4,
        link: "",
        name: "Account Management",
        subItems: [
          {
            link: "/accounts/businesses",
            name: "Manage Branches",
            isFullUrl: true
          },
          {
            link: "/startapprovalday",
            name: "Start Day Approval",
            isFullUrl: true,
            hide: true
          },

          {
            link: "/finance",
            name:
              Number(this.props.context.SessionData.isFinance) === 1
                ? "Finance"
                : "Business Activation",
            hide: ![1, 2].includes(
              Number(this.props.context.SessionData.isFinance)
            )
          }
        ],
        icon: AccountsIcon,
        hide:
          this.props.context.HeaderData.walletAmount.BusinessType === "Hospital"
            ? true
            : !this.props.context.hasPermission(2, 1) //use customer management permission
      },
      {
        moduleId: 135,
        link: "",
        name: "Team Management",
        subItems: [
          {
            link: "/user-activities",
            name: "User Activities",
            isFullUrl: true,
            hide: !this.props.context.hasPermission(133, 2)
          }
        ],
        icon: TeamIcon,
        hide: !this.props.context.hasPermission(135, 1)
      },
      {
        link: "",
        name: "Reports",
        subItems: [
          { link: "/reports", name: "Business Reports", isFullUrl: true },
          { link: "/wallet", name: "Statement" },
          {
            link: "/reports/order-management",
            name: "Order Management Reports",
            isFullUrl: true
          }
          // { link: "/reports/redistribution", name: "Redistribution" },
          // { link: "/reports/sales-partner", name: "Sales Partner" }
        ],
        icon: ReportIcon,
        hide: !this.props.context.hasPermission(5, 1)
      },

      {
        link: "/settings",
        name: "Settings",
        subItems: [],
        icon: SettingsIcon
        // hide: !this.props.context.hasPermission(4, 1)
      },
      {
        link: "/contact",
        name: "Contact",
        subItems: [],
        icon: ContactIcon,
        isMobile: true
      },
      {
        link: "/logout",
        name: "Logout",
        subItems: [],
        icon: LogoutIcon,
        isMobile: true,
        hide: this.props.context.isOffline
      },
      {
        link: "/notifications",
        name: "Notifications",
        subItems: [],
        icon: LogoutIcon,
        hide: true
      }
    ];

    return (
      <div className="bo-sidebar">
        <div className="bo-sidebar-body">
          <div className="close-sidebar">
            <div className="close-sidebar-text">
              <span className="hide">Account Info</span>
            </div>
            <div
              className="close-sidebar-trigger"
              onClick={this.hideSidebarMenu}
            >
              &#215;
            </div>
          </div>
          {!isDefaultSidebar ? (
            <React.Fragment>
              <div
                className="bo-sidebar-user intro-switch"
                onClick={this.toggleOtherBusiness.bind(this)}
              >
                {/* <div className="bo-sidebar-user-avatar">
                           {this.props.context.HeaderData.userProfileImg !== undefined &&
                           this.props.context.HeaderData.userProfileImg !== "" ? (
                              <img src={asset_url + this.props.context.HeaderData.userProfileImg} alt="" />
                           ) : (
                              <div />
                           )}
                        </div> */}
                <div className="bo-sidebar-user-avatar">
                  {this.props.context.SessionData.VCUserPhoto ? (
                    <img
                      src={
                        asset_url + this.props.context.SessionData.VCUserPhoto
                      }
                      alt=""
                    />
                  ) : (
                    <Avatar
                      name={this.props.context.SessionData.VCUserName}
                      size={35}
                    />
                  )}
                </div>
                <div className="bo-sidebar-user-details">
                  <div className="bo-sidebar-user-details-name">
                    {this.props.context.HeaderData.UserName}
                  </div>
                  <div className="bo-sidebar-user-details-service">
                    {this.props.context.HeaderData.BusinessName}
                  </div>
                  <div className="bo-sidebar-user-details-id">
                    <span className="business-id-text">ID:</span>
                    <span className="business-id-value">
                      {this.props.context.HeaderData.BusinessId}
                    </span>
                  </div>
                  {/* <div className="bo-sidebar-user-details-id">
                           <span className="business-id-text">ID:</span>
                           <span className="business-id-value">{this.props.context.HeaderData.BusinessId}</span>
                        </div> */}
                </div>
                <div className="bo-sidebar-switch">
                  <div className="bo-sidebar-switch-top">
                    <div className="bo-sidebar-switch-text" />
                    <div className="bo-sidebar-switch-icon">
                      {this.props.context.BusinessOwnerIdsWithNames.slice(0, 30)
                        .length > 1 ? (
                        <img
                          src="Images/ic_expand.svg"
                          className={this.state.imageSwitch}
                          alt=""
                        />
                      ) : (
                        <span />
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="bo-sidebar-user show--desktop bo-sidebar-user-agent">
                <div className="bo-sidebar-user-details">
                  Welcome,{" "}
                  <span>
                    {_truncText(this.props.context.SessionData.VCUserName, 15)}
                  </span>
                </div>
              </div>
            </React.Fragment>
          ) : (
            <div className="bo-sidebar-user intro-switch">
              <div className="bo-sidebar-user-avatar">
                {this.props.context.SessionData.VCUserPhoto ? (
                  <img
                    src={asset_url + this.props.context.SessionData.VCUserPhoto}
                    alt=""
                  />
                ) : (
                  <Avatar
                    name={this.props.context.SessionData.VCUserName}
                    size={35}
                  />
                )}
              </div>
              <div
                className="bo-sidebar-user-details"
                style={{ marginLeft: "10px" }}
              >
                {this.props.context.SessionData.VCUserName}
                <div className="bo-sidebar-user-details-service">
                  {this.props.context.HeaderData.BusinessName}
                </div>
              </div>
            </div>
          )}
          <div
            className={`${this.state.otherBusiness} bo-sidebar-switch-bottom`}
          >
            <div className="bo-sidebar-switch-options">
              <ul className="all-businesses">
                {this.props.context.BusinessOwnerIdsWithNames.map(
                  (idname, i) => (
                    <li
                      key={i}
                      data-id={idname.BusinessId}
                      onClick={this.changeBusiness.bind(this)}
                    >
                      {idname.BusinessName}
                    </li>
                  )
                )}
              </ul>
            </div>
          </div>
          {this.renderDownloadLink()}

          <ul className="bo-sidebar-menu">
            {this.props.context.showSidebar &&
              sidebarItems.map((el, i) => (
                <SidebarItem
                  key={i}
                  {...el}
                  location={this.props.location}
                  context={this.props.context}
                  history={this.props.history}
                  activeSidebarItem={this.state.activeSidebarItem}
                  updateActiveSidebarItem={this.updateActiveSidebarItem}
                />
              ))}
          </ul>
        </div>
      </div>
    );
  }
}

export default withAppData(withRouter(Sidebar));

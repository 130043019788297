let environmentType,
  backendHost,
  websiteHost,
  assetHost,
  marketUrl,
  enqAssetHost,
  flutterwaveKey,
  businessDashboardUrl,
  websiteUrl,
  userVconnectUrl,
  paystackKey,
  domainName,
  hostDomain,
  socketUrl,
  facebookApi,
  gmbApiKey,
  gmbClientId,
  gmbApiUrl,
  gmbScope,
  gmbHostUrl,
  freshSalesEndpoint,
  canvaKey,
  mapsApiKey,
  googleKey;

const hostname = window && window.location && window.location.hostname;
if (
  [
    "www.pro.omnibiz.com",
    "pro.omnibiz.com",
    "prelivepwa.omnibiz.com",
    "www.prelivepwa.omnibiz.com",
  ].includes(hostname)
) {
  backendHost = "https://omniapi.omnibiz.com/";
  websiteHost = "https://livesiteapi.omnibiz.com/api/";
  assetHost = "https://az742041.vo.msecnd.net/vcsites/vcimages/resource/";
  marketUrl = "https://market.omnibiz.com/";
  enqAssetHost =
    "https://az742041.vo.msecnd.net/vcsites/vcimages/resource/uploads/enquiry/businessquotations/";
  businessDashboardUrl = "https://pro.omnibiz.com";
  websiteUrl = "https://omnibiz.com/";
  userVconnectUrl = "https://vconnect.com/";
  // paystackKey = "pk_live_64d2b8956ed7913c264678100d367037f0a56b80";
  // paystackKey = "pk_test_cbf9f3b5a6b630695f5ed212a2fb0ec0f5bd6d2f";
  paystackKey = "pk_live_f47c18014e8198b5349f81c85a518dc6f89de7e4";
  flutterwaveKey = "FLWPUBK-2b0b05732c1fc630a877eeb75a6dafad-X";
  hostDomain = "https://pro.omnibiz.com";
  socketUrl = "https://notifications.vconnect.com/hub";
  environmentType = "production";
  freshSalesEndpoint = "https://freshsales.vconnect.com/api/";
  facebookApi = "https://lfbapi.omnibiz.com/";
  gmbApiKey = "AIzaSyAjWan9aIIkmRGKBWJQfMidRHFuI4vtGmM";
  gmbClientId =
    "910718044851-srv7e4nhv177fjep46b8f4ulqeblmbhp.apps.googleusercontent.com";
  gmbApiUrl = "https://mybusiness.googleapis.com/v4/";
  gmbScope = "https://www.googleapis.com/auth/business.manage";
  gmbHostUrl = "https://gmb.omnibiz.com/gmb/";
  canvaKey = "vq5we8aGtCSxbCq_1lBrP7QS";
  mapsApiKey = "AIzaSyAjWan9aIIkmRGKBWJQfMidRHFuI4vtGmM";
  domainName = ".omnibiz.com";
  googleKey =
    window.location.hostname === "pro.omnibiz.com"
      ? "AIzaSyCTM1qK7gSmcubfo0OdbEVnGWlKOjBuunw"
      : "AIzaSyAjWan9aIIkmRGKBWJQfMidRHFuI4vtGmM";
} else {
  backendHost = "https://bostageapi.omnibiz.com/";
  websiteHost = "https://stagesiteapi.omnibiz.com/api/";
  assetHost = "https://az742041.vo.msecnd.net/vcsites/vcimages/resource/";
  marketUrl = "https://stagemarket.omnibiz.com/";
  enqAssetHost =
    "https://az742041.vo.msecnd.net/vcsites/vcimages/resource/uploads/enquiry/businessquotations/";
  businessDashboardUrl = "https://pwa.omnibiz.com";
  websiteUrl = "https://stage.omnibiz.com/";
  userVconnectUrl = "http://stage.vconnect.co/";
  // paystackKey = "pk_test_cbf9f3b5a6b630695f5ed212a2fb0ec0f5bd6d2f";
  paystackKey = "pk_test_559a80048e81d9ae74eff1df416e7e80ce5f7db9";
  flutterwaveKey = "FLWPUBK-f7fc6d6497ace8d666f2d2a622b6f3c0-X";
  hostDomain = "https://pwa.omnibiz.com";
  socketUrl = "https://stagenotifications.omnibiz.com/hub";
  environmentType = "development";
  freshSalesEndpoint = "https://freshsales.vconnect.com/api/";
  facebookApi = "https://sfbapi.omnibiz.com/";
  gmbApiKey = "AIzaSyDRRTNm_XE8e0bylEwrh1aWTtuCIh7nifg";
  gmbClientId =
    "910718044851-srv7e4nhv177fjep46b8f4ulqeblmbhp.apps.googleusercontent.com";
  gmbApiUrl = "https://mybusiness.googleapis.com/v4/";
  gmbScope = "https://www.googleapis.com/auth/business.manage";
  gmbHostUrl =
    "https://cors-anywhere.herokuapp.com/https://boapiprelive.vconnect.com/gmb/";
  canvaKey = "fm_BRw2ElKTBLsJrGSepZ4-o";
  mapsApiKey = "AIzaSyDRRTNm_XE8e0bylEwrh1aWTtuCIh7nifg";
  domainName = hostname;
  googleKey = "AIzaSyDRRTNm_XE8e0bylEwrh1aWTtuCIh7nifg";
  //googleKey = "AIzaSyAI8cCwrFeYix0Rq-I3AUnSmd16j_haTAg";
}

if (hostname === "localhost") {
  environmentType = "development";
  domainName = "localhost";
  // backendHost = "https://omniapi.omnibiz.com/";
}

export const host_url = `${backendHost}api/`,
  website_hosturl = `${websiteHost}`,
  asset_url = `${assetHost}`,
  website_host = `${websiteHost}`,
  market_url = `${marketUrl}`,
  enqasset_url = `${enqAssetHost}`,
  business_dashboard_url = `${businessDashboardUrl}`,
  website_login = `${websiteUrl}login?rturl=${window.location.href}`,
  website_logout = `${websiteUrl}account/logout?returnUrl=${window.location.origin}/`,
  website_url = `${websiteUrl}`,
  user_vconnect_url = `${userVconnectUrl}`,
  paystack_key = `${paystackKey}`,
  flutterwave_key = `${flutterwaveKey}`,
  domain_name = `${domainName}`,
  host_domain = `${hostDomain}`,
  env_type = `${environmentType}`,
  socket_url = `${socketUrl}`,
  freshsales_api = `${freshSalesEndpoint}`,
  facebook_api = facebookApi,
  facebookID = "1911835115729684",
  twitterID = "0N5Tg9C1909ucKYARriaeSWrr",
  gmb_api_key = `${gmbApiKey}`,
  gmb_api_url = `${gmbApiUrl}`,
  gmb_client_id = `${gmbClientId}`,
  gmb_scope = `${gmbScope}`,
  gmb_host_url = `${gmbHostUrl}`,
  canva_key = `${canvaKey}`,
  maps_api_key = `${mapsApiKey}`,
  google_key = `${googleKey}`;

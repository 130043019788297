import React from "react";
import "./Styles/Pages/oldbrowser.scss";

class OldBrowser extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    document.title = "Unsupported Browser";
  }

  render() {
    return (
      <div className="old-browser">
        <div className="bo-header-content" />
        <div className="bo-body">
          <div className="bo-body-sidebar" />
          <div className="bo-body-content">
            <div className="old-browser-body">
              <div className="panel">
                <div className="old-browser-icon">
                  <img src="Images/old-browser.svg" alt="" />
                </div>
                <div className="old-browser-header">
                  Sorry, we currently do not support your browser
                </div>
                <div className="old-browser-subheader">
                  You can download the latest version of chrome or firefox for a
                  better experience
                </div>
                <div className="old-browser-options">
                  <a
                    href="https://www.google.com/chrome/"
                    className="btn outline grey"
                  >
                    <img src="Images/chrome.svg" alt="" />
                    Download Chrome
                  </a>
                  <a
                    href="https://www.mozilla.org/en-US/firefox/new/"
                    className="btn outline grey"
                  >
                    <img src="Images/firefox.svg" alt="" /> Download Firefox
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default OldBrowser;

import React from 'react';
import AppData from "../Context/AppData"

const withAppData = (WrappedComponent) => {
    const WithHOC = (props) => {
        return (
            <AppData.Consumer>
                {context => <WrappedComponent {...props} context={context} />}
            </AppData.Consumer>
        )
    }
    WithHOC.WrappedComponent = WrappedComponent;
    return WithHOC;
}

export default withAppData;
import React, { Component } from "react";

class Loading extends Component {
   render() {
      return (
         <div className="loading-container">
            {!this.props.errorText ? (
               <div
                  className="spinner"
                  style={{ margin: this.props.margin || "", width: this.props.text ? "auto" : "" }}>
                  <div className="rect1 spinner-bar" style={{ width: this.props.size || "" }} />
                  <div className="rect2 spinner-bar" style={{ width: this.props.size || "" }} />
                  <div className="rect3 spinner-bar" style={{ width: this.props.size || "" }} />
                  <div className="rect4 spinner-bar" style={{ width: this.props.size || "" }} />
                  <div className="rect5 spinner-bar" style={{ width: this.props.size || "" }} />
                  {this.props.text && (
                     <div className="spinner-text" dangerouslySetInnerHTML={{ __html: this.props.text }} />
                  )}
               </div>
            ) : (
               <div className="loading-container-error" style={{ margin: this.props.margin || "150px auto" }}>
                  <div className="body-container err-container" style={{ height: "auto" }}>
                     <div className="err-container-retry">
                        <div className="err-container-retry-header">{this.props.errorText}</div>
                        <span className="btn btn--primary btn--sm btn--crv" onClick={this.props.retry}>
                           <img src="Images/refresh.svg" alt="" />
                           Retry
                        </span>
                     </div>
                  </div>
               </div>
            )}
         </div>
      );
   }
}

export default Loading;

import React, { Component } from "react";
import Header from "./Layout/Header";
import Sidebar from "./Layout/Sidebar";
import ComponentLoading from "./Loaders/Loading";
import ModalLoading from "./Loaders/ModalLoading";
import Loadable from "react-loadable";
import platform from "platform";
import string from "prop-types";
import axios from "axios";
import { host_url, domain_name, gmb_host_url } from "./app-config";
import localForage from "localforage";
import cookie from "react-cookies";
import { Route, Switch, withRouter, Redirect } from "react-router-dom";
import AppData from "./Context/AppData";
import queryString from "query-string";
import ErrorReport from "./ErrorReport";
// import ReactGA from "react-ga";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
// import { static_userids } from "./staticUserIds";
import UpdateAppNotification from "./Components/Notifications/UpdateAppNotification";
import SyncOfflineData from "./Components/Notifications/SyncOfflineData";
import {
  _findArray,
  _uniqueArray,
  _isSameDate,
  _insertInString
} from "./Utils/helpers";
import "./Styles/app.scss";

function Loading(props) {
  if (props.error) {
    console.log("props.error: ", props.error);
    return (
      <ComponentLoading
        errorText={`We are unable to load your page at the moment. Please retry`}
        retry={() => window.location.reload()}
      />
    );
  } else if (props.timedOut) {
    return (
      <ComponentLoading
        errorText={`This took longer than we anticipated it will take to load. Please retry`}
        retry={() => window.location.reload()}
      />
    );
  } else if (props.pastDelay) {
    return <ComponentLoading />;
  } else {
    return null;
  }
}

//create message broadcast channel
let channel;
if (window.BroadcastChannel) {
  channel = new BroadcastChannel("sw-messages");
}

const SwitchToggle = Loadable({
  loader: () =>
    import(/* webpackChunkName: 'switchtoggle' */ "./Resources/Forms/Switch"),
  loading: Loading,
  delay: 100,
  timeout: 35000
});

const Survey = Loadable({
  loader: () =>
    import(/* webpackChunkName: 'survey' */ "./Components/Survey/Survey"),
  loading: Loading,
  delay: 100,
  timeout: 35000
});

const Sampling = Loadable({
  loader: () =>
    import(/* webpackChunkName: 'sampling' */ "./Components/Sampling/Sampling"),
  loading: Loading,
  delay: 100,
  timeout: 35000
});

// const SurveyPrompt = Loadable({
//    loader: () => import(/* webpackChunkName: 'surveyprompt' */ "./Components/Survey/SurveyPrompt"),
//    loading: Loading,
//    delay:100, timeout: 35000
// });

const Leads = Loadable({
  loader: () =>
    import(/* webpackChunkName: 'leads' */ "./Components/Leads/Leads"),
  loading: Loading,
  delay: 100,
  timeout: 35000
});

const Home = Loadable({
  loader: () => import(/* webpackChunkName: 'home' */ "./Components/Home"),
  loading: Loading,
  delay: 100,
  timeout: 35000
});

const UserInvite = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: 'userinvite' */ "./Components/Emails/UserInvite"
    ),
  loading: Loading,
  delay: 100,
  timeout: 35000
});

const Calendar = Loadable({
  loader: () =>
    import(/* webpackChunkName: 'calendar' */ "./Components/Calendar/Calendar"),
  loading: Loading,
  delay: 100
});

const Settings = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: 'settings-home' */ "./Components/Settings/SettingsHome"
    ),
  loading: Loading,
  delay: 100,
  timeout: 35000
});

const Subscribe = Loadable({
  loader: () =>
    import(/* webpackChunkName: 'subscribe' */ "./Components/Subscribe"),
  loading: Loading,
  delay: 100,
  timeout: 35000
});

const Download = Loadable({
  loader: () =>
    import(/* webpackChunkName: 'download' */ "./Components/Download"),
  loading: Loading,
  delay: 100,
  timeout: 35000
});

const AlertModal = Loadable({
  loader: () =>
    import(/* webpackChunkName: 'alertmodal' */ "./Resources/AlertModal"),
  loading: ModalLoading,
  delay: 100,
  timeout: 35000
});

const Profile = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: 'profile' */ "./Components/Profile/ProfileHome"
    ),
  loading: Loading,
  delay: 100,
  timeout: 35000
});

const KeywordServices = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: 'keywordservice' */ "./Components/Profile/KeywordService"
    ),
  loading: Loading,
  delay: 100,
  timeout: 35000
});

const Projects = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: 'projectslog' */ "./Components/Projects/ProjectsLog"
    ),
  loading: Loading,
  delay: 100,
  timeout: 35000
});

const Accounts = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: 'accountboard' */ "./Components/Accounts/AccountBoard"
    ),
  loading: Loading,
  delay: 100,
  timeout: 35000
});

const Finance = Loadable({
  loader: () =>
    import(/* webpackChunkName: 'finance' */ "./Components/Accounts/Finance"),
  loading: Loading,
  delay: 100,
  timeout: 35000
});

const StartDayApproval = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: 'finance' */ "./Components/Accounts/StartDayApproval"
    ),
  loading: Loading,
  delay: 100,
  timeout: 35000
});

const Tasks = Loadable({
  loader: () =>
    import(/* webpackChunkName: 'tasks' */ "./Components/Tasks/Tasks"),
  loading: Loading,
  delay: 100,
  timeout: 35000
});

const Wallet = Loadable({
  loader: () =>
    import(/* webpackChunkName: 'wallet' */ "./Components/Wallet/WalletLog"),
  loading: Loading,
  delay: 100,
  timeout: 35000
});

const Login = Loadable({
  loader: () =>
    import(/* webpackChunkName: 'login' */ "./Components/Auth/Login"),
  loading: Loading,
  delay: 100,
  timeout: 35000
});

const FreeTrial = Loadable({
  loader: () =>
    import(/* webpackChunkName: 'freetrial' */ "./Components/Emails/FreeTrial"),
  loading: Loading,
  delay: 100,
  timeout: 35000
});

const Refund = Loadable({
  loader: () =>
    import(/* webpackChunkName: 'refund' */ "./Components/Refund/RefundLog"),
  loading: Loading,
  delay: 100,
  timeout: 35000
});

const Contact = Loadable({
  loader: () =>
    import(/* webpackChunkName: 'contact' */ "./Components/Contact"),
  loading: Loading,
  delay: 100,
  timeout: 35000
});

const Listings = Loadable({
  loader: () =>
    import(/* webpackChunkName: 'listings' */ "./Components/Listings/Listings"),
  loading: Loading,
  delay: 100,
  timeout: 35000
});

// const OldListings = Loadable({
//    loader: () => import(/* webpackChunkName: 'oldlistings' */ "./Components/Listings/OldListings"),
//    loading: Loading,
//    delay:100, timeout: 35000
// });

const Posts = Loadable({
  loader: () =>
    import(/* webpackChunkName: 'posts' */ "./Components/Posts/Posts"),
  loading: Loading,
  delay: 100,
  timeout: 35000
});

const TestPost = Loadable({
  loader: () =>
    import(/* webpackChunkName: 'testpost' */ "./Components/Posts/Posts"),
  loading: Loading,
  delay: 100,
  timeout: 35000
});

const Reviews = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: 'reviewspage' */ "./Components/Reviews/Reviews"
    ),
  loading: Loading,
  delay: 100,
  timeout: 35000
});

const ReviewDetails = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: 'reviewspage' */ "./Components/Reviews/ReviewDetails"
    ),
  loading: Loading,
  delay: 100,
  timeout: 35000
});

const Tools = Loadable({
  loader: () =>
    import(/* webpackChunkName: 'toolspage' */ "./Components/Tools/ToolsHome"),
  loading: Loading,
  delay: 100,
  timeout: 35000
});

const Updates = Loadable({
  loader: () =>
    import(/* webpackChunkName: 'updates' */ "./Components/Posts/Updates"),
  loading: Loading,
  delay: 100,
  timeout: 35000
});

const AddressBook = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: 'addressbook' */ "./Components/Tools/AddressBook"
    ),
  loading: Loading,
  delay: 100,
  timeout: 35000
});

const Meeting = Loadable({
  loader: () =>
    import(/* webpackChunkName: 'meeting' */ "./Components/Meeting/Meeting"),
  loading: Loading,
  delay: 100,
  timeout: 35000
});

const AddVitals = Loadable({
  loader: () =>
    import(/* webpackChunkName: 'addvitals' */ "./Components/Tools/AddVitals"),
  loading: Loading,
  delay: 100,
  timeout: 35000
});
const UserActivities = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: 'useractivities' */ "./Components/Map/MapContainer"
    ),
  loading: Loading,
  delay: 100,
  timeout: 35000
});

const AddContact = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: 'addcustomer' */ "./Components/Tools/AddContact"
    ),
  loading: Loading,
  delay: 100,
  timeout: 35000
});

const Reports = Loadable({
  loader: () =>
    import(/* webpackChunkName: 'reports' */ "./Components/Reports/Reports"),
  loading: Loading,
  delay: 100,
  timeout: 35000
});

const OrderManagementReports = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: 'orderManagementReports' */ "./Components/Reports/OrderManagementReports/OrderManagementReportsHome"
    ),
  loading: Loading,
  delay: 100,
  timeout: 35000
});

const ReportsSalesPartner = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: 'reportsSalesPartner' */ "./Components/Reports/OrderManagementReports/SalesPartner/SalesPartnerHome"
    ),
  loading: Loading,
  delay: 100,
  timeout: 35000
});

const CustomerRegTable = Loadable({
  loader: () => import("./Components/Reports/CustomerRegTable"),
  loading: Loading,
  delay: 100,
  timeout: 35000
});

const BusinessScan = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: 'reports' */ "./Components/BusinessScan/BusinessScan"
    ),
  loading: Loading,
  delay: 100,
  timeout: 35000
});

const ProfileStats = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: 'profilestats' */ "./Components/Reports/ProfileStats"
    ),
  loading: Loading,
  delay: 100,
  timeout: 35000
});

const ReportsVisibilityDetails = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: 'reportsvisibilitydetails' */
      "./Components/Reports/ReportDetails/VisibilityDetails/VisibilityDetails"
    ),
  loading: Loading,
  delay: 100,
  timeout: 35000
});

// const DealConversionSummary = Loadable({
//    loader: () =>
//       import(
//          /* webpackChunkName: 'DealConversionSummary' */ "./Components/Reports/DealConversionSummary"
//       ),
//    loading: Loading,
//    delay:100, timeout: 35000
// });

// const CumulativeSummary = Loadable({
//    loader: () =>
//       import(
//          /* webpackChunkName: 'CumulativeSummary' */ "./Components/Reports/CumulativeSummary"
//       ),
//    loading: Loading,
//    delay:100, timeout: 35000
// });

const ReportsReputationDetails = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: 'reportsreputationdetails' */
      "./Components/Reports/ReportDetails/ReputationDetails/ReputationDetails"
    ),
  loading: Loading,
  delay: 100,
  timeout: 35000
});

const ReportsOrdersDetails = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: 'reportsordersdetails' */
      "./Components/Reports/ReportDetails/OrdersDetails/OrdersDetails"
    ),
  loading: Loading,
  delay: 100,
  timeout: 35000
});

const ReportsCustomerDetails = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: 'reportscustomerdetails' */
      "./Components/Reports/ReportDetails/CustomerDetails/CustomerDetails"
    ),
  loading: Loading,
  delay: 100,
  timeout: 35000
});

const ReportsTransactionDetails = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: 'reportstransactiondetails' */
      "./Components/Reports/ReportDetails/TransactionDetails/TransactionDetails"
    ),
  loading: Loading,
  delay: 100,
  timeout: 35000
});
const Shell = Loadable({
  loader: () => import(/* webpackChunkName: 'shell' */ "./Shell"),
  loading: Loading,
  delay: 100,
  timeout: 35000
});

const BoosterPlan = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: 'boosterplan' */ "./Components/Wallet/BoosterPlan"
    ),
  loading: Loading,
  delay: 100,
  timeout: 35000
});

const VerificationPlan = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: 'verificationplan' */ "./Components/Wallet/VerificationPlan"
    ),
  loading: Loading,
  delay: 100,
  timeout: 35000
});

const FreeTrialPage = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: 'freetrialpage' */ "./Components/Wallet/FreeTrial"
    ),
  loading: Loading,
  delay: 100,
  timeout: 35000
});

const WebsiteLandingPage = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: 'websitelandingpage' */ "./Components/Listings/Website/WebsiteLandingPage"
    ),
  loading: Loading,
  delay: 100,
  timeout: 35000
});

const ManageStore = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: 'managestore' */ "./Components/ManageStore/StoreHome"
    ),
  loading: Loading,
  delay: 100,
  timeout: 35000
});

const StoreSettings = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: 'storesettings' */ "./Components/ManageStore/StoreSettings"
    ),
  loading: Loading,
  delay: 100,
  timeout: 35000
});

const Products = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: 'products-page' */ "./Components/Posts/Products"
    ),
  loading: Loading,
  delay: 100,
  timeout: 35000
});

const Pipeline = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: 'customerpipeline' */ "./Components/Pipeline/Pipeline"
    ),
  loading: Loading,
  delay: 100,
  timeout: 35000
});

const PipelineReport = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: 'reportHome' */ "./Components/Pipeline/ReportHome"
    ),
  loading: Loading,
  delay: 100,
  timeout: 35000
});

const ReviewVitals = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: 'reviewVitals' */ "./Components/Health/ReviewVitals"
    ),
  loading: Loading,
  delay: 100,
  timeout: 15000
});

const InvoiceLog = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: 'invoicepage' */ "./Components/Invoice/InvoiceLog"
    ),
  loading: Loading,
  delay: 100,
  timeout: 35000
});

const OfflineNotif = Loadable({
  loader: () =>
    import(/* webpackChunkName: 'offlinenotif' */ "./Resources/OfflineNotif"),
  loading: Loading,
  delay: 100,
  timeout: 35000
});

const FetchOfflineData = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: 'fetchneccesarydata' */ "./Resources/FetchOfflineData"
    ),
  loading: Loading,
  delay: 100,
  timeout: 35000
});

const Stock = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: 'stockmanagement' */ "./Components/Stock/Stock"
    ),
  loading: Loading,
  delay: 100,
  timeout: 35000
});

const Notifications = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: 'notifications' */ "./Components/Notifications"
    ),
  loading: Loading,
  delay: 100,
  timeout: 35000
});

const Plans = Loadable({
  loader: () =>
    import(/* webpackChunkName: 'plans' */ "./Components/Plans/Plans"),
  loading: Loading,
  delay: 100,
  timeout: 35000
});

const SkuDetails = Loadable({
  loader: () =>
    import(/* webpackChunkName: 'skudetails' */ "./Components/SkuDetails"),
  loading: Loading,
  delay: 100,
  timeout: 35000
});

const StoreVisit = Loadable({
  loader: () =>
    import(/* webpackChunkName: 'storevisit' */ "./Components/StoreVisit"),
  loading: Loading,
  delay: 100,
  timeout: 35000
});

const FeedBack = Loadable({
  loader: () =>
    import(/* webpackChunkName: 'feedback-form' */ "./Components/FeedBack"),
  loading: Loading,
  delay: 100,
  timeout: 35000
});

const SyncLocalData = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: 'synclocaldata' */ "./Components/Settings/SyncLocalData"
    ),
  loading: Loading,
  delay: 100,
  timeout: 35000
});

let installPrompt;

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      unkValue: "",
      showDownloadMenu: false,
      loadAlertModal: false,
      loadSwitchModal: false,
      isAppInstalled: true,
      pageTitle: "",
      HeaderData: "",
      walletAmount: { Amount: 0, AmountFormated: "0", WalletExists: 0 },
      BusinessOwnerIdsWithNames: [],
      showOfflineStrip: false,
      pushNotification:
        "Notification" in window ? window.Notification.permission : "",
      SessionData: "",
      networkStatus: "",
      jsonHeader: "",
      hideInstallPrompt: "hide-prompt",
      services: [],
      gmbLocationId: "",
      currencyCode: "",
      leads: {
        filter: 0,
        unAcceptedLead: 0,
        marketCount: 0,
        unAcceptedMarket: [],
        marketParams: ""
      },
      AlertModal: {
        showAlertModal: false,
        showSuccess: false,
        showError: false,
        alertModalHeader: "",
        alertModalSubheader: "",
        autoClose: false
      },
      enableSteps: false,
      tourType: "",
      termsProps: "",
      loggedOut: false,
      rtUrl: "",
      subscriptionStatus: {},
      clevertapHeader: {
        headers: {
          "content-type": "application/json",
          source: "PWA"
        }
      },
      googleLocationId: "",
      isGoogleSyncing: false,
      googleBusinessDetails: "",
      facebookDetails: "",
      isFbSynced: false,
      isWebsiteSynced: false,
      isUserInvite: false,
      allBusinesses: [],
      offlineNotif: null,
      isManualOffline: false,
      showSyncOfflineData: false,
      showUpdateNotification: false,
      syncedOfflineData: false,
      syncDataError: false,
      showOfflineSwitchToggle: false,
      fetchingOfflineData: false,
      showSidebar: false,
      permissions: { modules: [], subModules: [], features: [] },
      appVersion: {},
      pushMessages: [],
      updatePageData: {},
      userLocation: {},
      syncRetries: 0,
      activatedBusinessSettings: [],
      contactTypes: []
    };

    this.getHeaderData = this.getHeaderData.bind(this);
    this.checkHeaderData = this.checkHeaderData.bind(this);
    this.closeUpdate = this.closeUpdate.bind(this);
    this.updateHeaders = this.updateHeaders.bind(this);
    this.showAlertModal = this.showAlertModal.bind(this);
    this.updateLeads = this.updateLeads.bind(this);
    this.updateWallet = this.updateWallet.bind(this);
    this.getUnaccepted = this.getUnaccepted.bind(this);
    this.updatePageTitle = this.updatePageTitle.bind(this);
    this.toggleInstallPrompt = this.toggleInstallPrompt.bind(this);
    this.hideInstallPrompt = this.hideInstallPrompt.bind(this);
    this.installApp = this.installApp.bind(this);
    this.logOut = this.logOut.bind(this);
    this.downloadApp = this.downloadApp.bind(this);
    this.login = this.login.bind(this);
    this.setCurrency = this.setCurrency.bind(this);
    this.updateToursDone = this.updateToursDone.bind(this);
    this.checkIfDoneTour = this.checkIfDoneTour.bind(this);
  }

  componentDidMount() {
    //check user invite
    this.checkUserInvite();

    //check if browser supports notificationsc
    if ("Notification" in window) {
      this.setState({ pushNotification: window.Notification.permission });
    }

    //listen for messages from service worker
    this.listenForBroadCastMessages();

    //check if manual offline has been activated
    localForage
      .getItem("isManualOffline", (err, value) => {
        if (value) {
          this.setState({
            isManualOffline: true,
            showOfflineSwitchToggle: true
          });
        }
      })
      .catch(err => {});

    //check network status
    this.checkNetworkStatus();
    window.addEventListener("online", this.checkNetworkStatus.bind(this));
    window.addEventListener("offline", this.checkNetworkStatus.bind(this));

    //add event listener for app installation
    window.addEventListener("appinstalled", this.appInstalled.bind(this));

    // Prompt users to download pwa
    window.addEventListener("beforeinstallprompt", e => {
      e.preventDefault();
      installPrompt = e;
      this.setState({ showDownloadMenu: true, isAppInstalled: false });
    });

    //add event listener for page refresh
    window.addEventListener("beforeunload", e => {
      if (channel) {
        if (this.state.meetingState) {
          channel.postMessage({
            action: "saveMeetingState",
            value: this.state.meetingState
          });
        } else if (this.state.meetingState === null) {
          channel.postMessage({ action: "deleteMeetingState" });
        }
      }
    });

    //check all business in  index db;
    localForage
      .getItem("allBusinesses", (err, value) => {
        if (value) {
          this.setState({ allBusinesses: value });
        }
      })
      .catch(err => {});

    // update app versions
    localForage.getItem("appVersion", (err, value) => {
      if (value && value.current) {
        this.setState({ appVersion: value });
      }
    });

    //check for push notifications messages
    localForage
      .getItem("pushMessages", (err, value) => {
        if (value) {
          if (this.state.HeaderData.BusinessId) {
            value = value.filter(el => {
              return (
                !el.businessId ||
                (el.businessId &&
                  Number(el.businessId) ===
                    Number(this.state.HeaderData.BusinessId))
              );
            });
          }
          this.setState({ pushMessages: value });
        }
        this.startPushMessagesTimeout();
      })
      .catch(err => {});

    //check activated business settings
    localForage.getItem("activatedBusinessSettings", (err, value) => {
      if (value) {
        this.setState({ activatedBusinessSettings: value });
      }
    });

    //check contact types
    localForage.getItem("contactTypes", (err, value) => {
      if (value) {
        this.setState({ contactTypes: value });
      }
    });
    //preload some common components
    Pipeline.preload();
    ManageStore.preload();
  }

  render() {
    if (this.state.isUserInvite) {
      return <UserInvite close={() => this.closeUserInvite()} />;
    } else if (this.state.loggedOut) {
      return <Login Login={this.login} />;
    } else if (
      !this.state.jsonHeader ||
      !this.state.SessionData ||
      !this.state.HeaderData
    ) {
      return (
        <div className="App">
          <Shell />
        </div>
      );
    } else {
      return (
        <AppData.Provider
          value={{
            pageTitle: this.state.pageTitle,
            updatePageTitle: this.updatePageTitle,
            HeaderData: this.state.HeaderData,
            getHeaderData: this.getHeaderData,
            walletAmount: this.state.walletAmount,
            BusinessOwnerIdsWithNames: this.state.BusinessOwnerIdsWithNames,
            SessionData: this.state.SessionData,
            jsonHeader: this.state.jsonHeader,
            updateHeaders: this.updateHeaders,
            clevertapHeader: this.state.clevertapHeader,
            showAlertModal: this.showAlertModal,
            networkStatus: this.state.networkStatus,
            pushNotification: this.state.pushNotification,
            leads: this.state.leads,
            updateLeads: this.updateLeads,
            updateWallet: this.updateWallet,
            getUnaccepted: this.getUnaccepted,
            togglePrompt: this.toggleInstallPrompt,
            downloadApp: this.downloadApp,
            logOut: this.logOut,
            isAppInstalled: this.state.isAppInstalled,
            Services: this.state.services,
            showDownloadMenu: this.state.showDownloadMenu,
            showTerms: this.showTerms,
            setCurrency: this.setCurrency,
            logClevertapEvent: this.logClevertapEvent,
            rtUrl: this.state.rtUrl,
            setRtUrl: this.setRtUrl,
            googleLocationId: this.state.googleLocationId,
            updateGoogleBusinessDetails: this.updateGoogleBusinessDetails,
            isGoogleSyncing: this.state.isGoogleSyncing,
            updateGoogleSyncStatus: this.updateGoogleSyncStatus,
            facebookDetails: this.state.facebookDetails,
            updateFacebookDetails: this.updateFacebookDetails,
            isDemoAccount: true,
            subscriptionStatus: this.state.subscriptionStatus,
            isFbSynced: this.state.isFbSynced,
            isWebsiteSynced: this.state.isWebsiteSynced,
            getFbBusinessDetails: this.getFbBusinessDetails,
            isPaid:
              this.state.HeaderData.IsBusinessFree &&
              [1, 3].includes(this.state.HeaderData.IsBusinessFree),
            subStatus: this.state.HeaderData.IsBusinessFree,
            publishers: this.state.HeaderData.walletAmount.ActivePlatform,
            publisherList: this.createPublisherLists(),
            allBusinesses: this.state.allBusinesses,
            logButtonClick: this.logButtonClick,
            history: this.props.history,
            location: this.props.location,
            showToast: this.showToast,
            showAlert: this.showAlert,
            userId: this.state.SessionData.VCUserContentID,
            showOfflineNotif: this.showOfflineNotif,
            isManualOffline: this.state.isManualOffline,
            updateManualOffline: this.updateManualOffline,
            isOffline:
              this.state.showOfflineStrip || this.state.isManualOffline,
            fetchingOfflineData: this.state.fetchingOfflineData,
            permissions: this.state.permissions,
            hasPermission: this.hasPermission,
            showSidebar: this.state.showSidebar,
            appVersion: this.state.appVersion,
            updateAppVersion: this.updateAppVersion,
            pushMessages: this.state.pushMessages,
            getPushMessages: this.getPushMessages,
            updateToursDone: this.updateToursDone,
            checkIfDoneTour: this.checkIfDoneTour,
            updatePageData: this.state.updatePageData,
            updateOfflineData: this.updateOfflineData,
            checkOfflineRequests: this.checkOfflineRequests,
            userLocation: this.state.userLocation,
            updateUserLocation: this.updateUserLocation,
            isSettingsActivated: this.isSettingsActivated,
            role: this.state.HeaderData.walletAmount.RoleName,
            businessType: this.state.HeaderData.walletAmount.BusinessType,
            saveToIndexDb: this.saveToIndexDb,
            getBusinessSettings: this.getBusinessSettings,
            contactTypes: this.state.contactTypes,
            getContactTypes: this.getContactTypes
          }}
        >
          <ErrorReport>
            <div className="App">
              <div>
                <div className="bo-header-content">
                  <Header />
                </div>
                {this.state.showUpdateNotification && (
                  <UpdateAppNotification
                    close={() =>
                      this.setState({ showUpdateNotification: false })
                    }
                    update={() =>
                      this.updateAppVersion(this.state.appVersion.latest)
                    }
                  />
                )}
                {this.state.showSyncOfflineData && (
                  <SyncOfflineData
                    syncing={!this.state.syncedOfflineData}
                    error={this.state.syncDataError}
                    close={() =>
                      this.setState({
                        showSyncOfflineData: false,
                        syncedOfflineData: false
                      })
                    }
                  />
                )}
                <div className="bo-body">
                  {!(
                    window.screen.width > 1014 && document.title === "Leads"
                  ) && (
                    <div className="bo-body-fixed">
                      {this.state.showOfflineStrip ? (
                        <div
                          className={`${
                            this.state.showOfflineStrip ? "show" : "hide"
                          } offline-strip`}
                        >
                          <span className="indicator" /> You are offline
                        </div>
                      ) : (
                        <div
                          className={`${
                            this.state.isManualOffline ? "show" : "hide"
                          } offline-strip`}
                        >
                          <span className="indicator" /> You are working
                          offline.{" "}
                        </div>
                      )}
                    </div>
                  )}
                  <div className="bo-body-sidebar">
                    <Sidebar />{" "}
                    {this.state.showOfflineSwitchToggle && (
                      <div className="bo-body-sidebar-bottom">
                        <div className="bo-body-sidebar-bottom-onlineswitch">
                          <SwitchToggle
                            label={"Go back online"}
                            noTrunc={true}
                            id={"disableofflinemode"}
                            checked={!this.state.isManualOffline}
                            onChange={() =>
                              this.disableManualOffline("isToggle")
                            }
                            size={"small"}
                          />{" "}
                        </div>{" "}
                      </div>
                    )}{" "}
                  </div>{" "}
                  <div
                    className="bo-body-content"
                    style={{
                      paddingTop:
                        this.state.isManualOffline ||
                        this.state.showOfflineStrip
                          ? "40px"
                          : "",
                      paddingBottom:
                        this.state.isManualOffline ||
                        this.state.showOfflineStrip
                          ? "20px"
                          : ""
                    }}
                  >
                    <Switch>
                      <Route exact path="/">
                        <Redirect from="/" to="/dashboard" />
                      </Route>{" "}
                      <Route exact path="/home">
                        <Redirect from="/home" to="/dashboard" />
                      </Route>{" "}
                      <Route exact path="/" component={Home} />
                      <Route exact path="/login" component={Login} />{" "}
                      <Route path="/accounts" component={Accounts} />
                      <Route path="/finance" component={Finance} />{" "}
                      <Route exact path="/tasks" component={Tasks} />
                      <Route path="/leads" component={Leads} />{" "}
                      <Route path="/projects" component={Projects} />
                      <Route path="/home" component={Home} />{" "}
                      <Route path="/dashboard" component={Home} />
                      <Route
                        path="/loading"
                        component={ComponentLoading}
                      />{" "}
                      <Route path="/refund" component={Refund} />
                      <Route exact path="/wallet" component={Wallet} />{" "}
                      <Route path="/wallet/subscribe" component={BoosterPlan} />{" "}
                      <Route path="/wallet/bbplans" component={BoosterPlan} />{" "}
                      <Route
                        path="/wallet/plan/booster"
                        component={BoosterPlan}
                      />{" "}
                      <Route
                        path="/wallet/plan/verification"
                        component={VerificationPlan}
                      />{" "}
                      <Route path="/wallet/trial" component={FreeTrialPage} />
                      <Route path="/freetrial" component={FreeTrial} />{" "}
                      <Route path="/subscribe" component={Subscribe} />
                      <Route path="/contact" component={Contact} />{" "}
                      <Route path="/profile" component={Profile} />{" "}
                      <Route
                        path="/services-keywords"
                        component={KeywordServices}
                      />{" "}
                      <Route
                        path="/keywords-services"
                        component={KeywordServices}
                      />{" "}
                      <Route path="/keywords" component={KeywordServices} />
                      <Route path="/download" component={Download} />{" "}
                      <Route path="/lead/:enquiryid" component={Leads} />
                      {/* <Route path="/market" component={Market} />{" "} */}
                      <Route exact path="/listings" component={Listings} />{" "}
                      <Route exact path="/listings/new" component={Listings} />{" "}
                      <Route
                        exact
                        path="/business-website"
                        component={WebsiteLandingPage}
                      />{" "}
                      <Route exact path="/posts" component={Posts} />
                      <Route exact path="/settings" component={Settings} />{" "}
                      <Route
                        exact
                        path="/settings/:section"
                        component={Settings}
                      />{" "}
                      <Route
                        exact
                        path="/settings/:section/:group"
                        component={Settings}
                      />{" "}
                      <Route exact path="/survey" component={Survey} />
                      <Route
                        exact
                        path="/posts/updates"
                        component={Updates}
                      />{" "}
                      <Route
                        exact
                        path="/posts/products"
                        component={Products}
                      />{" "}
                      <Route
                        exact
                        path="/posts/product/:productid"
                        component={Products}
                      />{" "}
                      <Route
                        exact
                        path="/posts/products/:filter"
                        component={Products}
                      />{" "}
                      <Route exact path="/test-posts" component={TestPost} />
                      <Route path="/reviews" component={Reviews} />{" "}
                      <Route exact path="/tools" component={Tools} />{" "}
                      <Route
                        exact
                        path="/tools/addressbook"
                        component={AddressBook}
                      />{" "}
                      {/* <Route exact path="/tools/invoices" component={Invoice} /> */}{" "}
                      <Route exact path="/reports" component={Reports} />{" "}
                      {/* <Route exact path="/activity" component={ActivitySummary} /> */}{" "}
                      {/* <Route exact path="/progress-reports" component={DealProgress} /> */}{" "}
                      {/* <Route exact path="/branch-reports" component={BranchReports} /> */}{" "}
                      <Route
                        exact
                        path="/customer-table"
                        component={CustomerRegTable}
                      />{" "}
                      <Route
                        exact
                        path="/pipeline-reports"
                        component={PipelineReport}
                      />{" "}
                      <Route
                        exact
                        path="/patient/vitals"
                        component={ReviewVitals}
                      />{" "}
                      <Route
                        exact
                        path="/business-scan"
                        component={BusinessScan}
                      />{" "}
                      <Route path="/reports/profile" component={ProfileStats} />{" "}
                      <Route
                        path="/reports/visibility"
                        component={ReportsVisibilityDetails}
                      />{" "}
                      <Route
                        path="/branch-reports/profile"
                        component={ProfileStats}
                      />{" "}
                      <Route
                        path="/branch-reports/visibility"
                        component={ReportsVisibilityDetails}
                      />{" "}
                      <Route exact path="/invoices" component={InvoiceLog} />{" "}
                      <Route
                        exact
                        path="/customer-pipeline"
                        component={Pipeline}
                      />{" "}
                      <Route
                        exact
                        path="/customer-pipeline/:id"
                        component={Pipeline}
                      />{" "}
                      <Route exact path="/store" component={ManageStore} />{" "}
                      <Route
                        exact
                        path="/store/sku/listing"
                        component={SkuDetails}
                      />
                      <Route
                        exact
                        path="/store/store-visit"
                        component={StoreVisit}
                      />
                      <Route exact path="/sampling" component={Sampling} />
                      <Route
                        exact
                        path="/store/settings"
                        component={StoreSettings}
                      />{" "}
                      <Route
                        exact
                        path="/store/:section"
                        component={ManageStore}
                      />{" "}
                      <Route
                        exact
                        path="/store/settings/:section"
                        component={StoreSettings}
                      />{" "}
                      <Route
                        exact
                        path="/store/settings/:section/:customerid"
                        component={StoreSettings}
                      />{" "}
                      <Route
                        path="/reports/reputation"
                        component={ReportsReputationDetails}
                      />{" "}
                      <Route
                        path="/reports/orders"
                        component={ReportsOrdersDetails}
                      />{" "}
                      <Route
                        path="/reports/customer"
                        component={ReportsCustomerDetails}
                      />{" "}
                      <Route
                        path="/reports/transaction"
                        component={ReportsTransactionDetails}
                      />{" "}
                      <Route
                        path="/reports/order-management"
                        component={OrderManagementReports}
                      />{" "}
                      <Route
                        path="/reports/sales-partner"
                        component={ReportsSalesPartner}
                      />{" "}
                      <Route
                        path="/branch-reports/reputation"
                        component={ReportsReputationDetails}
                      />{" "}
                      <Route
                        path="/branch-reports/orders"
                        component={ReportsOrdersDetails}
                      />{" "}
                      <Route
                        path="/branch-reports/customer"
                        component={ReportsCustomerDetails}
                      />{" "}
                      <Route
                        path="/branch-reports/transaction"
                        component={ReportsTransactionDetails}
                      />{" "}
                      <Route
                        path="/review/review-details"
                        component={ReviewDetails}
                      />{" "}
                      <Route exact path="/stock" component={Stock} />
                      <Route exact path="/stock/:mode" component={Stock} />{" "}
                      <Route
                        exact
                        path="/stock/:mode/:customerid"
                        component={Stock}
                      />{" "}
                      <Route exact path="/meeting">
                        <Meeting
                          updateMeetingState={this.updateMeetingState}
                          history={this.props.history}
                          location={this.props.location}
                        />
                      </Route>
                      <Route exact path="/meeting/:section">
                        <Meeting
                          updateMeetingState={this.updateMeetingState}
                          history={this.props.history}
                          location={this.props.location}
                        />
                      </Route>
                      <Route path="/calendar" component={Calendar} />{" "}
                      <Route path="/patients-task" component={Calendar} />{" "}
                      <Route
                        exact
                        path="/user-activities"
                        component={UserActivities}
                      />
                      <Route exact path="/plans" component={Plans} />{" "}
                      <Route exact path="/plans/:id" component={Plans} />{" "}
                      <Route path="/notifications" component={Notifications} />{" "}
                      <Route
                        path="/tools/addressbook/vitals"
                        component={AddVitals}
                      />{" "}
                      <Route
                        exact
                        path="/tools/addressbook/add"
                        component={AddContact}
                      />{" "}
                      <Route
                        path="/startapprovalday"
                        component={StartDayApproval}
                      />
                      <Route component={Home} />{" "}
                    </Switch>{" "}
                  </div>{" "}
                </div>{" "}
              </div>
              {this.state.loadAlertModal && (
                <AlertModal
                  showAlertModal={this.state.AlertModal.showAlertModal}
                  showSuccess={this.state.AlertModal.showSuccess}
                  showError={this.state.AlertModal.showError}
                  alertModalHeader={this.state.AlertModal.alertModalHeader}
                  alertModalSubheader={
                    this.state.AlertModal.alertModalSubheader
                  }
                />
              )}{" "}
              {/* {document.title === "Dashboard" && <SurveyPrompt history={this.props.history} />} */}
              {this.state.offlineNotif && (
                <OfflineNotif
                  {...this.state.offlineNotif}
                  close={() =>
                    this.setState({
                      offlineNotif: null
                    })
                  }
                >
                  {" "}
                </OfflineNotif>
              )}
              {/* neccessary for showing wifi offline image*/}{" "}
              <img
                src="Images/no-wifi.svg"
                alt=""
                style={{
                  position: "absolute",
                  opacity: 0,
                  visibility: "hidden",
                  height: 0,
                  overflow: "hidden"
                }}
              />{" "}
              {this.state.fetchingOfflineData && (
                <FetchOfflineData
                  isBusinessSwitch={this.state.fetchingOfflineData === 2}
                  isUpdating={this.state.fetchingOfflineData === 3}
                  retry={() =>
                    this.setState({ fetchingOfflineData: false }, () => {
                      this.setState({ fetchingOfflineData: true });
                    })
                  }
                  close={() => {
                    this.setState({
                      fetchingOfflineData: false
                    });
                    this.persistStorage();
                  }}
                />
              )}
              {this.state.showSyncLocalData && (
                <SyncLocalData
                  isMainPage={true}
                  close={() => this.setState({ showSyncLocalData: false })}
                />
              )}
              {![
                "/tools/addressbook",
                "/plans",
                "/customer-pipeline",
                "/reports/order-management"
              ].includes(this.props.location.pathname) &&
                !this.props.location.pathname.includes("lead") && <FeedBack />}
              <ToastContainer />
            </div>{" "}
          </ErrorReport>{" "}
        </AppData.Provider>
      );
    }
  }

  async checkIfDoneTour(tour) {
    let localSettings = await localForage.getItem("settings");
    if (
      localSettings &&
      localSettings.tours &&
      localSettings.tours.length > 0
    ) {
      if (localSettings.tours.includes(tour)) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  async updateToursDone(tour) {
    let localSettings = await localForage.getItem("settings");
    if (
      localSettings &&
      localSettings.tours &&
      localSettings.tours.length > 0
    ) {
      let tours = localSettings.tours;
      if (!localSettings.tours.includes(tour)) {
        tours.push(tour);
        localSettings.tours = tours;
      }
    } else if (localSettings && !localSettings.tours) {
      localSettings.tours = [tour];
    } else {
      localSettings = {};
      localSettings.tours = [tour];
    }
    localForage.setItem("settings", localSettings);
  }
  checkOfflineRequests = () => {
    if (channel) {
      this.setState({ showSyncLocalData: false }, () => {
        this.setState({ showSyncLocalData: true });
      });
    }
  };

  updateOfflineData = async flag => {
    let updatedLocalData = await localForage.getItem("updatedLocalData");
    if (updatedLocalData) {
      if (!_isSameDate(updatedLocalData, new Date()) || flag === 3) {
        this.setState({ fetchingOfflineData: flag || 2 }, () => {});
      }
    } else {
      this.setState({ fetchingOfflineData: flag || 2 }, () => {});
    }
  };

  checkStartDayStatus = () => {
    let startDay = cookie.load("currentDay");
    if (
      this.state.HeaderData.UserId &&
      (!startDay ||
        (startDay &&
          Number(startDay.userId) !== Number(this.state.HeaderData.UserId))) &&
      this.state.HeaderData.walletAmount &&
      this.state.HeaderData.walletAmount.IsStartDayEnable &&
      this.state.HeaderData.BusinessOwnerIdsWithNames &&
      _findArray(
        this.state.HeaderData.BusinessOwnerIdsWithNames,
        "BusinessId",
        Number(this.state.HeaderData.BusinessId)
      ) &&
      _findArray(
        this.state.HeaderData.BusinessOwnerIdsWithNames,
        "BusinessId",
        Number(this.state.HeaderData.BusinessId)
      ).IsBO !== 1
    ) {
      if (
        ![
          "/",
          "/dashboard",
          "/settings",
          "/contact",
          "/settings/offline",
          "/settings/app",
          "/settings/password"
        ].includes(this.props.location.pathname)
      ) {
        this.props.history.replace("/dashboard");
      }
    }
  };

  updateMeetingState = meetingState => {
    this.setState({ meetingState });
  };

  setCurrency(amount, countrycode) {
    let cc =
      countrycode ||
      Number(
        this.state.SessionData.VCCountry.constructor === Array
          ? this.state.SessionData.VCCountry[0]
          : this.state.SessionData.VCCountry
      );
    if (cc) {
      if (amount || amount === 0) {
        let amtString = amount.toString();
        if (amtString.length > 6 && amtString.indexOf(".") === -1) {
          const symbol =
            amtString.length > 12
              ? " trillion"
              : amtString.length > 9
              ? " billion"
              : amtString.length > 6
              ? " million"
              : "";
          amount =
            amtString.length > 12
              ? amount / 1000000000000
              : amtString.length > 9
              ? amount / 1000000000
              : amtString.length > 6
              ? amount / 1000000
              : amount;
          return {
            __html:
              cc === 1
                ? `&#8358;${amount
                    .toFixed(1)
                    .toString()
                    .replace(/\.0+$|(\.[0-9]*[1-9])0+$/, "")}${symbol}`
                : cc === 2
                ? `&#8373;${amount
                    .toFixed(1)
                    .toString()
                    .replace(/\.0+$|(\.[0-9]*[1-9])0+$/, "")}${symbol}`
                : cc === 6
                ? `K${amount
                    .toFixed(1)
                    .toString()
                    .replace(
                      /\.0 + $ | (\.[0 - 9] * [1 - 9]) 0 + $ /,
                      ""
                    )}${symbol}`
                : ""
          };
        } else {
          return {
            __html:
              cc === 1
                ? `&#8358;${amount.toLocaleString()}`
                : cc === 2
                ? `&#8373;${amount.toLocaleString()}`
                : cc === 6
                ? `K${amount.toLocaleString()}`
                : ""
          };
        }
      } else {
        return {
          __html:
            cc === 1 ? "&#8358;" : cc === 2 ? "&#8373;" : cc === 6 ? "K" : ""
        };
      }
    } else {
      if (amount || amount === 0) {
        return {
          __html: amount.toLocaleString()
        };
      }
    }
  }

  setRtUrl = url => {
    this.setState({
      rtUrl: url
    });
  };

  login(bus, busid) {
    const tempLocation = window.location.href;
    if (tempLocation.includes("newpassword")) {
      window.location.href = window.location.origin + "/#/";
    }

    let sessionData = {};
    let buslists = bus.BusinessesList;
    this.setState({
      allBusinesses: bus.BusinessesList
    });
    let businessNames = [];
    let businessIds = [];
    let businessCountries = [];

    if (buslists.length > 0) {
      buslists.forEach(business => {
        businessNames.push(business.BusinessName);
        businessIds.push(business.BusinessId);
        businessCountries.push(business.countrycode);
      });

      if (busid) {
        window.location.href = window.location.href.split("?")[0];
        let tempIds = businessIds;
        let tempNames = businessNames;
        let tempCountries = businessCountries;

        const idPosition = tempIds.indexOf(Number(busid));
        if (idPosition > -1) {
          const tempId = tempIds[idPosition];
          const tempName = tempNames[idPosition];
          const tempCountry = tempCountries[idPosition];
          tempIds.splice(idPosition, 1);
          tempIds.unshift(tempId);
          tempNames.splice(idPosition, 1);
          tempNames.unshift(tempName);
          tempCountries.splice(idPosition, 1);
          tempCountries.unshift(tempCountry);

          sessionData.VCUserBusinessIds = tempIds;
          sessionData.VCUserBusinessNames = tempNames;
          sessionData.VCCountry = tempCountries;
        } else {
          sessionData.VCUserBusinessIds = businessIds;
          sessionData.VCUserBusinessNames = businessNames;
          sessionData.VCCountry = businessCountries;
        }
      } else {
        sessionData.VCUserBusinessIds = businessIds;
        sessionData.VCUserBusinessNames = businessNames;
        sessionData.VCCountry = businessCountries;
      }
      sessionData.VCUserContentID = bus.UserId;
      sessionData.VCLoginID = bus.EmailId;
      sessionData.VCUserName = bus.ContactName;
      sessionData.VCUserPhoto = bus.Photo;
      sessionData.VCUserPhone = bus.Phone;
      sessionData.VCUserToken = bus.JsonToken;
      sessionData.isAgent = bus.IsAgent;
      sessionData.isFinance = bus.IsFinance;
      const expires = new Date();
      expires.setDate(new Date().getDate() + 180);

      cookie.save("vcLoginToken", sessionData.VCUserToken, {
        domain: `${domain_name}`,
        expires,
        path: "/"
      });

      // cookie.save("vcSessionData", sessionData, {
      //    domain: `${domain_name}`,
      //    expires,
      //    path: "/"
      // });

      localForage
        .setItem("sessionData", sessionData)
        .then(() => {
          this.encryptCookies(sessionData, "cacheData");
          this.startPushMessagesTimeout();
        })
        .catch(err => {});
    } else {
      this.setState({ loggedOut: true });
    }
  }

  logOut(logOutFromAllTabs) {
    this.props.history.push("/");
    this.setState({ loggedOut: true }, () => {});
    const cookies = [
      "vci",
      "vcb",
      "vcn",
      "vcc",
      "vli",
      "vun",
      "vup",
      "vcp",
      "vti",
      "unk",
      "vcbIndex",
      "vcSessionData",
      "vcHeaderData",
      "vcLoginToken",
      "vcag",
      "vcfi"
    ];
    cookies.forEach(el => {
      cookie.remove(el, { path: "/", domain: `${domain_name}` });
    });
    cookie.remove("activeBusinessId", { path: "/", domain: `${domain_name}` });
    this.setState({ pushMessages: [] });
    clearTimeout(this.pushMesssagesTimeout);
    clearInterval(this.pushMessagesInterval);
    window.sessionStorage.clear();
    window.localStorage.clear();
    localForage
      .iterate((value, key) => {
        if (key !== "settings" && key !== "appVersion") {
          localForage
            .removeItem(key)
            .then()
            .catch(err => {
              console.log(err);
            });
        }
      })
      .then(() => {
        if (channel && !logOutFromAllTabs) {
          channel.postMessage({ action: "logOutFromAllTabs" });
        }
      })
      .catch(err => {
        console.log(err);
      });
  }

  toggleInstallPrompt() {
    if (
      this.state.hideInstallPrompt === "hide-prompt" &&
      this.state.isAppInstalled === false &&
      installPrompt &&
      window.screen.width < 768
    ) {
      this.setState({ hideInstallPrompt: "show-prompt" });
    } else if (this.state.hideInstallPrompt === "show-prompt") {
      this.setState({ hideInstallPrompt: "hide-prompt" });
    }
  }

  hideInstallPrompt() {
    this.setState({ hideInstallPrompt: "hide-prompt" }, () => {
      this.logClevertapEvent("BOPWaInstallBannerClose");
    });
  }

  installApp() {
    this.toggleInstallPrompt();
    installPrompt.prompt();
    installPrompt.userChoice.then(choiceResult => {
      if (choiceResult.outcome === "accepted") {
        this.setState({ isAppInstalled: true });
      } else {
      }
      installPrompt = null;
    });
  }

  downloadApp() {
    if (installPrompt) {
      installPrompt.prompt();
      installPrompt.userChoice.then(choiceResult => {
        if (choiceResult.outcome === "accepted") {
          this.setState({ isAppInstalled: true });
        } else {
        }
        installPrompt = null;
      });
    }
  }

  async checkHeaderData(message) {
    try {
      let headerData = await localForage.getItem("headerData");
      if (headerData) {
        this.setState({ walletAmount: headerData.walletAmount });
        this.setState({
          BusinessOwnerIdsWithNames: headerData.BusinessOwnerIdsWithNames
        });
        this.setState({ HeaderData: headerData }, () => {
          this.setPermissions();
          if (message !== undefined) {
            if (message === "showalert") {
              this.getHeaderData("showalert");
            } else {
              this.getHeaderData(message);
            }
          } else {
            this.getHeaderData();
          }
        });
      } else {
        if (message !== undefined) {
          if (message === "showalert") {
            this.getHeaderData("showalert");
          } else {
            this.getHeaderData(message);
          }
        } else {
          this.getHeaderData();
        }
      }
    } catch (error) {
      console.log(error);
    }
  }

  getHeaderData(message) {
    axios
      .get(
        `${host_url}AccountDetails/GetBusinessHeaderData`,
        this.state.jsonHeader
      )
      .then(response => {
        if (response.data.StatusCode === 1) {
          this.loadSubscription();
          this.getBusinessSettings();
          this.getContactTypes();
          localForage.setItem("headerData", response.data.Result).catch(err => {
            console.log(err);
          });
          this.setState({
            walletAmount: response.data.Result.walletAmount
          });
          this.setState({
            BusinessOwnerIdsWithNames:
              response.data.Result.BusinessOwnerIdsWithNames
          });
          this.setState({ HeaderData: response.data.Result }, () => {
            this.setPermissions();
            if (message !== undefined) {
              if (message === "showalert") {
                let alertModalDetails = {
                  showAlertModal: true,
                  showSuccess: true,
                  showError: false,
                  alertModalHeader: `You have successfully switched to ${this.state.HeaderData.BusinessName}`,
                  alertModalSubheader: "",
                  autoClose: true
                };
                let homePage = window.location.origin + "/#/";
                window.location.href = homePage;
                this.showAlertModal(alertModalDetails);
              } else {
                let businessSwitchRtUrl = window.localStorage.getItem(
                  "businessSwitchRtUrl"
                );

                if (businessSwitchRtUrl) {
                  if (businessSwitchRtUrl.includes("lead")) {
                    window.location.href = JSON.parse(businessSwitchRtUrl);
                  } else {
                    let alertModalDetails = {
                      showAlertModal: true,
                      showSuccess: true,
                      showError: false,
                      alertModalHeader: ``,
                      alertModalSubheader: `You can now manage <b>${this.state.HeaderData.BusinessName}</b>'s profile`,
                      autoClose: true
                    };
                    window.location.href = JSON.parse(businessSwitchRtUrl);
                    this.showAlertModal(alertModalDetails);
                  }
                }
              }
            }
          });
        } else {
          if (message !== undefined) {
            let alertModalDetails = {
              showAlertModal: true,
              showSuccess: false,
              showError: true,
              alertModalHeader: `You were unable to switch your business`,
              alertModalSubheader: "Please try again",
              autoClose: true
            };
            let homePage = window.location.origin + "/#/";
            window.location.href = homePage;
            this.showAlertModal(alertModalDetails);
          }
        }
      })
      .catch(error => {
        console.log(error);
        if (message !== undefined) {
          let alertModalDetails = {
            showAlertModal: true,
            showSuccess: false,
            showError: true,
            alertModalHeader: `You were unable to switch your business`,
            alertModalSubheader: "Please try again",
            autoClose: true
          };
          let homePage = window.location.origin + "/#/";
          window.location.href = homePage;
          this.showAlertModal(alertModalDetails);
        }
      });
  }

  async createSession(cacheData) {
    try {
      let vcbIndex;
      if (cookie.load("vcbIndex")) {
        if (cookie.load("vcbIndex").url !== window.location.hostname) {
          vcbIndex = cookie.load("vcbIndex").id;
          cookie.remove("vcbIndex", {
            path: "/",
            domain: `${domain_name}`
          });
        } else {
          vcbIndex = 0;
        }
      } else {
        vcbIndex = 0;
      }
      let unk = cookie.load("unk"); //User api token
      // let vcSessionData = cookie.load("vcSessionData");
      let vcSessionData = await localForage.getItem("sessionData");
      let VCUserBusinessIds;
      let VCUserBusinessNames;
      let VCCountry;
      if (vcSessionData) {
        VCUserBusinessIds = vcSessionData.VCUserBusinessIds.toString(); //User Business ids
        VCUserBusinessNames = vcSessionData.VCUserBusinessNames.toString(); //User Business names
        VCCountry = vcSessionData.VCCountry.toString();
      } else {
        VCUserBusinessIds = cookie.load("vcb"); //User Business ids
        VCUserBusinessNames = cookie.load("vcn"); //User Business names
        VCCountry = cookie.load("vcc");
      }
      let VCUserContentID = cookie.load("vci"); //User id
      // let VCCountry = cookie.load("vcc"); //User Business Countries
      let VCLoginID = cookie.load("vli"); //User email
      let VCUserName = cookie.load("vun"); //User Name
      let VCUserPhoto =
        cookie.load("vup") === undefined ? "" : cookie.load("vup"); //User photo
      let VCUserPhone = cookie.load("vcp"); //User Phone
      let VCUserToken = cookie.load("vti"); //User api token
      let VCAgent = cookie.load("vcag"); //agent status
      let VCFinance = cookie.load("vcfi"); //finance status
      let decryptHeader = {
        headers: {
          "content-type": "application/json",
          source: "PWA"
        }
      };
      let sessionParams = {
        flag: 3,
        UniqueId: unk,
        KeyAndValueList: [
          { key: "VCUserContentID", value: VCUserContentID },
          { key: "VCUserBusinessIds", value: VCUserBusinessIds },
          { key: "VCUserBusinessNames", value: VCUserBusinessNames },
          { key: "VCCountry", value: VCCountry },
          { key: "VCLoginID", value: VCLoginID },
          { key: "VCUserName", value: VCUserName },
          { key: "VCUserPhoto", value: VCUserPhoto },
          { key: "VCUserPhone", value: VCUserPhone },
          { key: "VCUserToken", value: VCUserToken },
          { key: "VCAgent", value: VCAgent },
          { key: "VCFinance", value: VCFinance }
        ]
      };

      axios
        .post(
          `${host_url}OnBoarding/EncryptAndDecryptString`,
          sessionParams,
          decryptHeader
        )
        .then(response => {
          if (response.data.StatusCode === 1) {
            let SessionData = {
              VCUserContentID: 0,
              VCUserBusinessIds: [],
              VCUserBusinessNames: [],
              VCCountry: [],
              VCLoginID: string,
              VCUserName: string,
              VCUserPhoto: string,
              VCUserPhone: string,
              VCUserToken: string
            };
            for (var index = 0; index < response.data.Result.length; index++) {
              switch (response.data.Result[index].key) {
                case "VCUserContentID":
                  SessionData.VCUserContentID =
                    response.data.Result[index].value;
                  break;
                case "VCUserBusinessIds":
                  let businessIds = response.data.Result[index].value.split(
                    ","
                  );
                  let activeBusinessId = businessIds[vcbIndex];
                  if (activeBusinessId) {
                    businessIds.splice(vcbIndex, 1);
                    businessIds.unshift(activeBusinessId);
                  }

                  SessionData.VCUserBusinessIds = businessIds;
                  break;
                case "VCUserBusinessNames":
                  let businessNames = response.data.Result[index].value.split(
                    ","
                  );
                  let activeBusinessName = businessNames[vcbIndex];
                  if (activeBusinessName) {
                    businessNames.splice(vcbIndex, 1);
                    businessNames.unshift(activeBusinessName);
                  }
                  SessionData.VCUserBusinessNames = businessNames;
                  break;
                case "VCCountry":
                  let countryCodes = response.data.Result[index].value.split(
                    ","
                  );
                  let activeCountryCode = countryCodes[vcbIndex];
                  if (activeCountryCode) {
                    countryCodes.splice(vcbIndex, 1);
                    countryCodes.unshift(activeCountryCode);
                  }
                  SessionData.VCCountry = countryCodes;
                  break;
                case "VCLoginID":
                  SessionData.VCLoginID = response.data.Result[index].value;
                  break;
                case "VCUserName":
                  SessionData.VCUserName = response.data.Result[index].value;
                  break;
                case "VCUserPhoto":
                  SessionData.VCUserPhoto = response.data.Result[index].value;
                  break;
                case "VCUserPhone":
                  SessionData.VCUserPhone = response.data.Result[index].value;
                  break;
                case "VCUserToken":
                  SessionData.VCUserToken = response.data.Result[index].value;
                  break;
                case "VCAgent":
                  SessionData.isAgent = response.data.Result[index].value;
                  break;
                case "VCFinance":
                  SessionData.isFinance = response.data.Result[index].value;
                  break;
                default:
              }
            }

            this.setState({ SessionData }, () => {
              this.addProfileForWebPush();
              this.addUserBehavior();
              localForage.setItem("tagsSent", "yes").catch(err => {
                console.log(err);
              });
              localForage.setItem("sessionData", SessionData).catch(err => {
                console.log(err);
              });
              let userId;
              if (
                vcSessionData &&
                Number(vcSessionData.VCUserContentID) ===
                  Number(this.state.SessionData.VCUserContentID)
              ) {
                userId = vcSessionData.VCUserContentID;
              } else {
                localForage.removeItem("headerData").catch(err => {
                  console.log(err);
                });
                localForage.removeItem("sessionData").catch(err => {
                  console.log(err);
                });
                userId = this.state.SessionData.VCUserContentID;
              }
              let jsonHeader = {
                headers: {
                  "content-type": "application/json",
                  source: "PWA",
                  Token: this.state.SessionData.VCUserToken,
                  BusinessId: this.state.SessionData.VCUserBusinessIds[0],
                  UserId: userId,
                  countrycode: this.state.SessionData.VCCountry[0]
                }
              };

              localForage
                .setItem("jsonHeader", jsonHeader)
                .then(() => {
                  this.setState({ jsonHeader: jsonHeader }, () => {
                    this.checkHeaderData();
                    //check if request is being made from login, if yes. cache neccessary data
                    if (cacheData) {
                      setTimeout(() => {
                        this.setState({ fetchingOfflineData: true });
                      }, 500);
                      //refresh other tabs/windows and login
                      setTimeout(() => {
                        if (channel) {
                          channel.postMessage({
                            action: "login"
                          });
                        }
                      }, 3000);
                    } else {
                    }
                  });
                })
                .catch(err => {});
            });
          } else {
            this.deleteLocalData();
          }
        })
        .catch(error => {
          console.log(error);
          this.deleteLocalData();
        });
    } catch (error) {
      console.log(error);
    }
  }

  getUnaccepted() {
    if (this.state.jsonHeader !== "") {
      axios
        .get(`${host_url}enquiry/GetUnacceptedLeadCount`, this.state.jsonHeader)
        .then(response => {
          if (response.data.StatusCode === 1) {
            let unAccepted = response.data.Result;
            let unAcceptedLeads = unAccepted.filter(seg => {
              return seg.Servicename === "ALL";
            });
            let marketServices = unAccepted.filter(seg => {
              return (
                seg.Servicename !== "ALL" && seg.Servicename !== "(Market)"
              );
            });
            this.setState({ services: marketServices });
            let unAcceptedMarket = unAccepted.filter(seg => {
              return seg.Servicename === "(Market)";
            });

            let firstService;
            let otherServices = [];
            let marketParams;
            let marketCount = 0;

            if (unAcceptedMarket.length > 0) {
              marketCount = unAcceptedMarket[0].UnacceptedCount;
            }

            if (marketServices.length > 0) {
              marketServices.forEach((serv, i) => {
                if (i === 0) {
                  firstService = serv.ServiceUrl;
                }
                if (i !== 0) {
                  otherServices.push(serv.ServicenameUrl);
                }
              });
              marketParams = `${firstService}${
                otherServices.length > 0
                  ? `?services=${otherServices.toString()}`
                  : ""
              }`;

              if (marketParams !== undefined) {
                marketParams = marketParams.toLowerCase();
              } else {
                marketParams = "";
              }
            }

            let leadData = {
              filter: this.state.leads.filter,
              unAcceptedLead: unAcceptedLeads[0].UnacceptedCount,
              unAcceptedMarket: unAcceptedMarket,
              marketCount: marketCount,
              marketParams: marketParams
            };
            this.updateLeads(leadData);
          }
        })
        .catch(error => {
          console.log(error);
        });
    }
  }

  closeUpdate() {
    document
      .querySelector(".update-notification-cont")
      .classList.remove("show-update-notification");
  }

  updateApp() {
    document
      .querySelector(".update-notification-cont")
      .classList.remove("show-update-notification");
    let loadingPage = window.location.origin + "/#/loading";
    window.location.href = loadingPage;
    setTimeout(() => {
      window.location.href = window.location.origin;
    }, 3000);
  }

  updateAppVersion = latestVersion => {
    let appVersion = {};
    appVersion.current = latestVersion;
    appVersion.latest = latestVersion;
    appVersion.created = new Date();
    localForage
      .setItem("appVersion", appVersion)
      .then(() => {
        if (channel) {
          channel.postMessage({ action: "reload" });
        }
        window.location.reload();
      })
      .catch(function(err) {
        console.log(err);
      });
  };

  async updateHeaders(jsonHeader, businessSwitchRtUrl) {
    try {
      this.setState({ showSidebar: false, pushMessages: [] });
      clearTimeout(this.pushMesssagesTimeout);
      clearInterval(this.pushMessagesInterval);
      let loadingPage = window.location.origin + "/#/loading";
      window.location.href = loadingPage;
      await localForage.removeItem("headerData");
      let initialSessionData = await localForage.getItem("sessionData");
      let tempSessionData = await localForage.getItem("sessionData");

      if (tempSessionData) {
        let tempIds = tempSessionData.VCUserBusinessIds;
        let tempNames = tempSessionData.VCUserBusinessNames;
        let tempCountries = tempSessionData.VCCountry;

        const idPosition = tempIds.indexOf(
          jsonHeader.headers.BusinessId.toString()
        );
        if (idPosition > -1) {
          const tempId = tempIds[idPosition];
          const tempName = tempNames[idPosition];
          const tempCountry = tempCountries[idPosition];
          tempIds.splice(idPosition, 1);
          tempIds.unshift(tempId);
          tempNames.splice(idPosition, 1);
          tempNames.unshift(tempName);
          tempCountries.splice(idPosition, 1);
          tempCountries.unshift(tempCountry);

          const expires = new Date();
          expires.setDate(new Date().getDate() + 180);
          cookie.save(
            "vcbIndex",
            { id: idPosition, url: window.location.hostname },
            {
              domain: `${domain_name}`,
              expires,
              path: "/"
            }
          );

          localForage
            .setItem("sessionData", tempSessionData)
            .then(() => {})
            .catch(err => {});

          this.setState({ SessionData: tempSessionData }, () => {
            this.addProfileForWebPush();
          });
        }
      }

      setTimeout(() => {
        this.setState({ jsonHeader: "" });
        window.sessionStorage.clear();
        window.localStorage.clear();
        localForage
          .iterate((value, key) => {
            if (
              key !== "settings" &&
              key !== "sessionData" &&
              key !== "appVersion"
            ) {
              localForage
                .removeItem(key)
                .then()
                .catch(err => {
                  console.log(err);
                });
            }
          })
          .then(() => {
            if (initialSessionData) {
              jsonHeader.headers.countrycode = tempSessionData.VCCountry[0];
              const sessionData = this.state.SessionData;
              sessionData.VCCountry = tempSessionData.VCCountry;
              this.setState({ SessionData: sessionData });
            }

            localForage
              .setItem("jsonHeader", jsonHeader)
              .then(() => {
                this.setState({ jsonHeader: jsonHeader }, () => {
                  this.checkHeaderData(businessSwitchRtUrl || "showalert");
                  this.setState({ fetchingOfflineData: 2 }, () => {
                    this.startPushMessagesTimeout();
                  });
                });
              })
              .catch(err => {
                console.log(err);
              });
          })
          .catch(err => {
            console.log(err);
          });
      }, 500);
    } catch (error) {
      console.log(error);
    }
  }

  updateLeads(leadData) {
    this.setState({ leads: leadData }, () => {
      // console.log(this.state.leads);
    });
  }

  updateWallet(walletData) {
    this.setState({ walletAmount: walletData });
  }

  updatePageTitle(pagetitle) {
    this.setState({ pageTitle: pagetitle });
  }

  showAlertModal(alertModalDetails) {
    this.setState({ loadAlertModal: true, AlertModal: alertModalDetails });
    let autoClose = alertModalDetails.autoClose;
    if (autoClose !== undefined) {
      if (autoClose === true) {
        setTimeout(() => {
          this.closeAlertModal();
        }, 5000);
      }
    }
  }

  closeAlertModal() {
    let alertModalDetails = {
      showAlertModal: false,
      showSuccess: false,
      showError: false,
      alertModalHeader: "",
      alertModalSubheader: "",
      autoClose: false
    };

    this.showAlertModal(alertModalDetails);
  }

  checkNetworkStatus() {
    if (window.navigator) {
      if (window.navigator.onLine) {
        this.setState({ networkStatus: "Online", showOfflineStrip: false });
      } else {
        if (window.screen.width < 1015) {
          this.setState({
            networkStatus: "Offline",
            showOfflineStrip: true
          });
        } else {
          if (document.title !== "Leads") {
            this.setState({
              networkStatus: "Offline",
              showOfflineStrip: true
            });
          }
        }
      }
    }
  }

  //fire clevertap event to log page visits
  logPageVisit = () => {
    let clevertapData = {
      d: [
        {
          identity: this.state.HeaderData.BusinessId,
          type: "event",
          evtName: "BOPWAPageVisit",
          evtData: {
            PageName: this.props.location.pathname
              ? this.props.location.pathname.split("/")[1]
              : ""
          }
        }
      ]
    };
    axios
      .post(
        `${host_url}Logger/SendClaverTapEvent`,
        clevertapData,
        this.state.clevertapHeader
      )
      .then(response => {})
      .catch(error => {});
  };

  addUserBehavior() {
    if (this.state.HeaderData.BusinessId) {
      this.logPageVisit();
      if (window.dataLayer) {
        window.dataLayer.push({
          bid: this.state.HeaderData.BusinessId
        });
      }
    }

    let decryptHeader = {
      headers: {
        "content-type": "application/json",
        source: "PWA"
      }
    };
    let data = {
      BrowserSessionId:
        Math.random()
          .toString(36)
          .substring(2, 15) +
        Math.random()
          .toString(36)
          .substring(2, 15),
      BrowserInfo: JSON.stringify({
        browserName: platform.name,
        browserVersion: platform.version,
        OsName: platform.os.family,
        OsVersion: platform.os.version,
        phoneName: platform.product,
        appVersion: this.state.appVersion.current || "",
        memory: navigator.deviceMemory || ""
      }),
      UserId: this.state.SessionData.VCUserContentID,
      PreviousUrl: document.referrer,
      PageUrl: window.location.href,
      Location: "",
      CampaignName: "",
      keyword: "",
      TotalRecords: 0,
      SessionId:
        cookie.load("sessionId") !== null && cookie.load("sessionId") !== ""
          ? Number(cookie.load("sessionId"))
          : 0,
      SystemType: " ",
      SystemId: " ",
      BizIds: "",
      BusinessId:
        this.state.SessionData.VCUserBusinessIds === undefined ||
        this.state.SessionData.VCUserBusinessIds.length === 0
          ? 0
          : this.state.SessionData.VCUserBusinessIds[0],
      UserLocation: "",
      PageId: 0,
      SearchPageLogId: 0,
      PageNumber: 0,
      IsPaid: " ",
      Domain: window.location.hostname,
      source: "PWA"
    };

    axios
      .post(`${host_url}Logger/AddUserBehavior`, data, decryptHeader)
      .then(response => {
        if (!cookie.load("sessionId")) {
          let now = new Date();
          now.setDate(now.getDate() + 1);
          cookie.save("sessionId", response.data.Result.SessionId, {
            domain: `${domain_name}`,
            path: "/"
          });
          // document.cookie = `sessionId=${response.data.Result.SessionId}; expires=${now.toUTCString()};domain=${domain_name};path=/`;
        }
      })
      .catch(error => {
        console.log(error);
        //goto login page
      });
  }

  appInstalled() {
    this.setState({ isAppInstalled: true });
    setTimeout(() => {
      toast.success("You can now access our app from your homescreen", {
        position: toast.POSITION.BOTTOM_LEFT,
        className: "success-toast",
        autoClose: 4000
      });
    }, 10000);

    this.logClevertapEvent("BOPwaHomeScreenAdd");
  }

  deleteLocalData() {
    if (
      !this.state.jsonHeader ||
      !this.state.SessionData ||
      !this.state.HeaderData
    ) {
      this.logOut();
    }
  }

  encryptCookies(sessionData, cacheData) {
    let decryptHeader = {
      headers: {
        "content-type": "application/json",
        source: "PWA"
      }
    };
    let sessionParams = {
      flag: 4,
      KeyAndValueList: [
        {
          key: "VCUserContentID",
          value: sessionData.VCUserContentID ? sessionData.VCUserContentID : ""
        },
        {
          key: "VCUserBusinessIds",
          value: sessionData.VCUserBusinessIds
            ? sessionData.VCUserBusinessIds.toString()
            : ""
        },
        {
          key: "VCUserBusinessNames",
          value: sessionData.VCUserBusinessNames
            ? sessionData.VCUserBusinessNames.toString()
            : ""
        },
        {
          key: "VCCountry",
          value: sessionData.VCCountry ? sessionData.VCCountry.toString() : ""
        },
        {
          key: "VCLoginID",
          value: sessionData.VCLoginID ? sessionData.VCLoginID : ""
        },
        {
          key: "VCUserName",
          value: sessionData.VCUserName ? sessionData.VCUserName : ""
        },
        {
          key: "VCUserPhoto",
          value: sessionData.VCUserPhoto ? sessionData.VCUserPhoto : ""
        },
        {
          key: "VCUserPhone",
          value: sessionData.VCUserPhone ? sessionData.VCUserPhone : ""
        },

        {
          key: "VCUserToken",
          value: sessionData.VCUserToken ? sessionData.VCUserToken : ""
        },
        {
          key: "VCAgent",
          value: sessionData.isAgent || ""
        },
        {
          key: "VCFinance",
          value: sessionData.isFinance || ""
        }
      ]
    };
    axios
      .post(
        `${host_url}OnBoarding/EncryptAndDecryptString`,
        sessionParams,
        decryptHeader
      )
      .then(response => {
        if (response.data.StatusCode === 1) {
          const result = response.data.Result;

          let d = new Date();
          d.setTime(d.getTime() + 24 * 60 * 60 * 1000 * 500);
          result.forEach(data => {
            if (data.key === "VCUserContentID") {
              document.cookie = `${"vci"}=${
                data.value
              };domain=${domain_name};path=/;expires=${d.toGMTString()}`;
            }
            if (data.key === "VCUserBusinessIds") {
              document.cookie = `${"vcb"}=${
                data.value
              };domain=${domain_name};path=/;expires=${d.toGMTString()}`;
            }
            if (data.key === "VCUserBusinessNames") {
              document.cookie = `${"vcn"}=${
                data.value
              };domain=${domain_name};path=/;expires=${d.toGMTString()}`;
            }
            if (data.key === "VCCountry") {
              document.cookie = `${"vcc"}=${
                data.value
              };domain=${domain_name};path=/;expires=${d.toGMTString()}`;
            }
            if (data.key === "VCLoginID") {
              document.cookie = `${"vli"}=${
                data.value
              };domain=${domain_name};path=/;expires=${d.toGMTString()}`;
            }
            if (data.key === "VCUserName") {
              document.cookie = `${"vun"}=${
                data.value
              };domain=${domain_name};path=/;expires=${d.toGMTString()}`;
            }
            if (data.key === "VCUserPhoto") {
              document.cookie = `${"vup"}=${
                data.value
              };domain=${domain_name};path=/;expires=${d.toGMTString()}`;
            }
            if (data.key === "VCUserPhone") {
              document.cookie = `${"vcp"}=${
                data.value
              };domain=${domain_name};path=/;expires=${d.toGMTString()}`;
            }
            if (data.key === "VCUserToken") {
              document.cookie = `${"vti"}=${
                data.value
              };domain=${domain_name};path=/;expires=${d.toGMTString()}`;
            }
            if (data.key === "VCAgent") {
              document.cookie = `${"vcag"}=${
                data.value
              };domain=${domain_name};path=/;expires=${d.toGMTString()}`;
            }
            if (data.key === "VCFinance") {
              document.cookie = `${"vcfi"}=${
                data.value
              };domain=${domain_name};path=/;expires=${d.toGMTString()}`;
            }
            if (data.key === "unk") {
              document.cookie = `${"unk"}=${
                data.value
              };domain=${domain_name};path=/;expires=${d.toGMTString()}`;
            }
          });

          const parsedUrl = queryString.parse(this.props.location.search);
          if (parsedUrl && parsedUrl.rturl) {
            window.location.href = parsedUrl.rturl;
          } else {
            setTimeout(() => {
              this.setState({ loggedOut: false });
            }, 3000);
            this.createSession(cacheData);
          }

          /*
           */
        } else {
          this.logOut();
        }
      })
      .catch(error => {
        console.log(error);
      });
  }

  async checkLocalData() {
    try {
      // let sessionData = cookie.load("vcSessionData");
      // let headerData = cookie.load("vcHeaderData");
      let sessionData = await localForage.getItem("sessionData");
      let headerData = await localForage.getItem("headerData");
      if (sessionData && headerData) {
        let jsonHeader = {
          headers: {
            "content-type": "application/json",
            source: "PWA",
            Token: sessionData.VCUserToken,
            BusinessId: sessionData.VCUserBusinessIds[0],
            UserId: sessionData.VCUserContentID,
            countrycode: sessionData.VCCountry[0]
          }
        };
        let clevertapHeader = {
          headers: {
            "content-type": "application/json",
            source: "PWA",
            countrycode: sessionData.VCCountry[0]
          }
        };

        sessionData.VCCountry = sessionData.VCCountry[0];
        this.setState(
          {
            HeaderData: headerData,
            SessionData: sessionData,
            clevertapHeader: clevertapHeader
          },
          () => {
            this.setPermissions();
            this.setState({ jsonHeader: jsonHeader }, () => {
              this.createSession();
            });

            // this.checkSubscriptionStatus();
          }
        );
      } else {
        this.createSession();
      }
    } catch (error) {
      console.log(error);
    }
  }

  loadMouseFlow() {
    window._mfq = window._mfq || [];
    const script = document.createElement("script");
    script.type = "text/javascript";
    script.async = true;
    script.src =
      "//cdn.mouseflow.com/projects/4c463f55-7e62-4547-a953-41d7f35b416b.js";
    document.getElementsByTagName("head")[0].appendChild(script);
  }

  updateGoogleBusinessDetails = (details, addphotos) => {
    this.setState({ googleBusinessDetails: details }, () => {
      this.setState({ googleLocationId: details.locationId }, () => {
        if (addphotos) {
          this.updateGoogleSyncStatus();
        }
      });
    });
  };

  updateFacebookDetails = details => {
    this.setState({ facebookDetails: details }, () => {});
  };

  updateGoogleSyncStatus = nophotos => {
    this.setState({ isGoogleSyncing: true }, () => {
      if (!nophotos) {
        this.getMediaPhotos();
      }
    });
  };

  getMediaPhotos = () => {
    let prm = {
      ReadFlag: 0,
      serviceId: 0,
      RecordPerPage: 100,
      PageNo: 1
    };
    axios
      .post(`${host_url}BOProfile/getAddVideoPhoto`, prm, this.state.jsonHeader)
      .then(response => {
        if (response.data.StatusCode === 1 && response.data.Result.length > 0) {
          let photoUrls = [];
          let photoResults = response.data.Result.filter(d => d.isType === 0);
          if (photoResults.length > 0) {
            photoResults.forEach(el => {
              photoUrls.push(el.imageYouTubeUrl);
            });

            // return;
            //this.postPhotosToGoogle(photoUrls);
            this.setState({ isGoogleSyncing: false });
          } else {
            this.setState({ isGoogleSyncing: false });
          }
        } else {
          this.setState({ isGoogleSyncing: false });
        }
      })
      .catch(error => {
        console.log(error);
        this.setState({ isGoogleSyncing: false });
      });
  };

  postPhotosToGoogle = photoUrls => {
    photoUrls.forEach((elem, i) => {
      const data = {
        locationId: this.state.googleLocationId,
        name: this.state.HeaderData.BusinessName,
        category: "EXTERIOR",
        description: this.state.HeaderData.BusinessName,
        sourceUrl: elem
      };
      axios
        .post(`${gmb_host_url}createMediaPhoto`, data, this.state.jsonHeader)
        .then(response => {
          let error = response.data.error;
          if (
            error &&
            error.details &&
            error.details.length > 0 &&
            error.details[0].errorDetails.length > 0 &&
            error.details[0].errorDetails[0]
          ) {
            this.logGmbClevertapEvent(
              error.details[0].errorDetails[0].message,
              error.details[0].errorDetails[0].value
            );
          } else if (error.message) {
            this.logGmbClevertapEvent(error.message);
          }
          if (i === photoUrls.length - 1) {
            this.setState({ isGoogleSyncing: false });
          }
        })
        .catch(error => {
          this.setState({ isGoogleSyncing: false });
          // this.setState({ isGoogleSyncing: false });
        });
    });
  };

  logGmbClevertapEvent = (message, value) => {
    let clevertapData = {
      d: [
        {
          identity: 1,
          type: "event",
          evtName: "GMBSycData",
          evtData: {
            Source: "GMB",
            MessageType: `${message}${value ? `,${value}` : ""}`,
            Businessid: this.state.HeaderData.BusinessId
          }
        }
      ]
    };
    axios
      .post(
        `${host_url}Logger/SendClaverTapInternalEvent`,
        clevertapData,
        this.state.clevertapHeader
      )
      .then(response => {})
      .catch(error => {});
  };

  getGoogleBusinessDetails = () => {
    axios
      .get(`${gmb_host_url}getBusinessDetails`, this.state.jsonHeader)
      .then(response => {
        if (response.data.errorCode === 200) {
          let businessDetails = response.data.data.businessDetails;
          this.setState({ googleBusinessDetails: businessDetails });
          if (businessDetails.locationId) {
            this.setState({
              googleLocationId: businessDetails.locationId
            });
          } else {
            this.setState({ googleLocationId: null });
          }
        }
      })
      .catch(error => {
        console.log(error);
      });
  };

  async checkLoginStatus() {
    try {
      let VCUserContentID = cookie.load("vci");
      if (VCUserContentID) {
        let sessionData = await localForage.getItem("sessionData");
        if (sessionData) {
          let activeBusinessId = cookie.load("activeBusinessId");
          if (
            !activeBusinessId ||
            (sessionData.VCUserBusinessIds &&
              (sessionData.VCUserBusinessIds.includes(
                activeBusinessId.toString()
              ) ||
                sessionData.VCUserBusinessIds.includes(
                  Number(activeBusinessId)
                )))
          ) {
            this.checkLocalData();
            this.loadMouseFlow();
          } else {
            this.logOut();
          }
        } else {
          this.createSession();
        }
      } else {
        this.setState({ loggedOut: true }, () => {
          window.sessionStorage.clear();
          window.localStorage.clear();
          localForage
            .iterate((value, key) => {
              if (key !== "settings" && key !== "appVersion") {
                localForage
                  .removeItem(key)
                  .then()
                  .catch(err => {
                    console.log(err);
                  });
              }
            })
            .then(() => {})
            .catch(err => {
              console.log(err);
            });
        });
      }
    } catch (error) {
      console.log(error);
    }
  }

  checkAppData() {
    setTimeout(() => {
      if (document.title !== "Login") {
        if (
          !this.state.jsonHeader ||
          !this.state.SessionData ||
          !this.state.HeaderData
        ) {
          this.logOut();
        }
      }
    }, 20000);
  }

  autoLogin(ck, businessId) {
    cookie.remove("vcSessionData", { path: "/", domain: `${domain_name}` });
    cookie.remove("sessionId", { path: "/", domain: `${domain_name}` });
    cookie.remove("vcHeaderData", { path: "/" });
    cookie.remove("activeBusinessId", { path: "/", domain: `${domain_name}` });
    localForage.removeItem("headerData");
    localForage.removeItem("sessionData");
    axios
      .post(
        `${host_url}user/Autologin`,
        { ClaimBizKey: ck },
        this.state.clevertapHeader
      )
      .then(response => {
        if (response.data.StatusCode === 1) {
          let businessDetails = response.data.Result;
          if (businessDetails) {
            this.login(businessDetails, businessId);
          }
        } else {
          this.logOut();
        }
      })
      .catch(error => {
        this.logOut();
      });
  }

  checkUserInvite() {
    if (this.props.location.search) {
      const urlParams = queryString.parse(this.props.location.search);
      if (urlParams.inviteEmail && urlParams.businessId) {
        this.setState({ isUserInvite: true });
      } else {
        //check autologin parameter
        this.checkAutoLogin();
      }
    } else {
      //check autologin parameter
      this.checkAutoLogin();
    }
  }

  checkAutoLogin() {
    if (window.location.href.includes("verifyandlogin")) {
      this.loadMouseFlow();
      window.sessionStorage.clear();
      window.localStorage.clear();
      localForage
        .iterate((value, key) => {
          if (key !== "settings" && key !== "appVersion") {
            localForage
              .removeItem(key)
              .then()
              .catch(err => {
                console.log(err);
              });
          }
        })
        .then(() => {})
        .catch(err => {
          console.log(err);
        });
      if (this.props.location.search) {
        const urlParams = queryString.parse(this.props.location.search);
        const claimKey = urlParams.c;
        const businessId = urlParams.businessId;
        const isMarket = urlParams.isMarket;
        if (isMarket) {
          localForage.setItem("isMarket", true).catch(err => {
            console.log(err);
          });
        }
        if (claimKey) {
          this.autoLogin(claimKey, businessId);
        } else {
          this.checkLoginStatus();
          this.checkAppData();
        }
      }
    } else {
      if (this.props.location.search) {
        const urlParams = queryString.parse(this.props.location.search);
        const isMarket = urlParams.isMarket;
        if (isMarket) {
          localForage.setItem("isMarket", true).catch(err => {
            console.log(err);
          });
        }
      }
      this.checkLoginStatus();
      this.checkAppData();
    }
  }

  loadSubscription() {
    axios
      .get(`${host_url}common/Getbusinesspackage`, this.state.jsonHeader)
      .then(response => {
        if (response.data.StatusCode === 1) {
          this.setState({ subscriptionStatus: response.data.Result });
          localForage
            .setItem("subscriptionStatus", response.data.Result)
            .catch(err => {
              console.log(err);
            });
        } else {
        }
      })
      .catch(error => {
        console.log(error);
      });
  }

  checkSubscriptionStatus() {
    localForage.getItem("subscriptionStatus", (err, value) => {
      if (value) {
        this.setState({ subscriptionStatus: value }, () =>
          this.loadSubscription()
        );
      } else {
        this.loadSubscription();
      }
    });
  }

  //listen for broadcast messages from service worker
  listenForBroadCastMessages = () => {
    if (channel) {
      channel.addEventListener("message", event => {
        if (event.data && event.data.action) {
          const action = event.data.action;
          if (action === "reload") {
            window.location.reload();
          } else if (action === "syncOfflineData") {
            this.setState({ showSyncOfflineData: true });
          } else if (action === "syncedOfflineData") {
            setTimeout(() => {
              this.setState({ syncedOfflineData: true }, () => {
                if (navigator.onLine) {
                  this.updateOfflineData();
                }
                setTimeout(() => {
                  this.setState({
                    showSyncOfflineData: false,
                    syncedOfflineData: false,
                    syncDataError: false
                  });
                }, 4000);
              });
            }, 5500);
          } else if (action === "syncError") {
            this.setState({ syncDataError: true }, () => {
              setTimeout(() => {
                this.setState({
                  showSyncOfflineData: false,
                  syncedOfflineData: false,
                  syncDataError: false
                });
              }, 2000);
            });
          } else if (action === "appVersionUpdated") {
            if (event.data.versionDetails) {
              this.setState({ appVersion: event.data.versionDetails });
            }
          } else if (action === "newPushNotification") {
            setTimeout(() => {
              localForage
                .getItem("pushMessages", (err, value) => {
                  if (value) {
                    if (this.state.HeaderData.BusinessId) {
                      value = value.filter(el => {
                        return (
                          !el.businessId ||
                          (el.businessId &&
                            Number(el.businessId) ===
                              Number(this.state.HeaderData.BusinessId))
                        );
                      });
                    }
                    this.setState({
                      pushMessages: value
                    });
                  }
                })
                .catch(err => {});
            }, 1500);
          } else if (action === "updatePageData") {
            this.setState({ updatePageData: {} }, () => {
              this.setState({ updatePageData: event.data });
            });
          } else if (action === "checkQueueTimer") {
            setTimeout(() => {
              channel.postMessage({ action: "checkRequestQueue" });
            }, 3000);
          } else if (action === "logOutFromAllTabs") {
            this.logOut("logOutFromAllTabs");
          } else if (action === "login") {
            window.location.reload();
          } else if (
            action === "showUpdateNotification" &&
            !this.state.fetchingOfflineData &&
            !this.state.loggedOut
          ) {
            setTimeout(() => {
              this.setState({ showUpdateNotification: true });
            }, 3000);
          }
        }
      });
    }
  };

  logClevertapEvent = (eventName, source) => {
    let clevertapData = {
      d: [
        {
          identity: this.state.HeaderData.BusinessId,
          type: "event",
          evtName: eventName,
          evtData: {
            BusinessId: this.state.HeaderData.BusinessId,
            Mode:
              !navigator.onLine || this.state.isManualOffline
                ? "Offline"
                : "Online",
            Source: source || ""
          }
        }
      ]
    };
    axios
      .post(
        `${host_url}Logger/SendClaverTapEvent`,
        clevertapData,
        this.state.clevertapHeader
      )
      .then(response => {})
      .catch(error => {});
  };

  logButtonClick = (modulename, buttonname) => {
    const data = {
      LeadId: 1,
      ModuleName: modulename,
      ButtonName: buttonname
    };
    axios
      .post(`${host_url}Market/AddButtonClickLog`, data, this.state.jsonHeader)
      .then(response => {})
      .catch(error => {});
  };

  //create publisher lists that will be used for creating products, posts and filter reports
  createPublisherLists = () => {
    const publishers = this.state.HeaderData.walletAmount.ActivePlatform;
    if (publishers) {
      let publisherLists = [
        {
          PlatformName: "Vconnect",
          PlatformId: 1,
          PlatformID: 1,
          synced: publishers.includes("1")
        },
        {
          PlatformName: "Google My Business",
          PlatformId: 2,
          PlatformID: 2,
          synced: publishers.includes("2")
        },
        {
          PlatformName: "Facebook",
          PlatformId: 3,
          PlatformID: 3,
          synced: publishers.includes("3")
        },
        {
          PlatformName: "Your Website",
          PlatformId: 4,
          PlatformID: 4,
          synced: publishers.includes("4")
        },
        {
          PlatformName: "Twitter",
          PlatformId: 5,
          PlatformID: 5,
          synced: publishers.includes("5")
        }
      ];
      return publisherLists;
    } else {
      return [];
    }
  };

  //show toast

  showToast = (msg, type) => {
    if (type === "error") {
      toast.error(msg, {
        position: toast.POSITION.BOTTOM_LEFT,
        autoClose: 2000
      });
    } else if (type === "info") {
      toast.info(msg, {
        position: toast.POSITION.BOTTOM_LEFT,
        autoClose: 2000
      });
    } else {
      toast.success(msg, {
        position: toast.POSITION.BOTTOM_LEFT,
        className: "success-toast",
        autoClose: 2000
      });
    }
  };

  showAlert = (msg, type) => {
    if (type === "error") {
      let alertModalDetails = {
        showAlertModal: true,
        showSuccess: false,
        showError: true,
        alertModalSubheader: msg,
        autoClose: true
      };
      this.showAlertModal(alertModalDetails);
    } else {
      let alertModalDetails = {
        showAlertModal: true,
        showSuccess: true,
        showError: false,
        alertModalSubheader: msg,
        autoClose: true
      };
      this.showAlertModal(alertModalDetails);
    }
  };

  showOfflineNotif = details => {
    this.setState({ offlineNotif: details }, () => {
      if (!details.manualClose) {
        setTimeout(() => {
          this.setState({ offlineNotif: null });
        }, 4500);
      }
    });
  };

  closeUserInvite = () => {
    this.setState({ isUserInvite: false, loggedOut: true }, () => {
      window.location.href = window.location.href.split("?")[0];
    });
  };

  componentWillUnmount() {
    clearInterval(this.checkPermissionId);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.HeaderData !== this.state.HeaderData) {
      if (this.state.HeaderData) {
        //check if user has started day and allowed to view non dashboard pages
        this.checkStartDayStatus();
        const expires = new Date();
        expires.setDate(new Date().getDate() + 180);
        cookie.save("activeBusinessId", this.state.HeaderData.BusinessId, {
          domain: `${domain_name}`,
          expires,
          path: "/"
        });
      }
    } else {
      if (
        this.props.location &&
        this.props.location.pathname !== prevProps.location.pathname
      ) {
        this.addUserBehavior();
        //check if user has started day and allowed to view non dashboard pages
        this.checkStartDayStatus();
        //sync offline data when any of the urls below is visited
      }
    }
  }

  updateManualOffline = async val => {
    this.setState(
      { isManualOffline: val, showOfflineSwitchToggle: val },
      () => {
        if (!val) {
          this.disableManualOffline();
        } else {
          localForage.setItem("isManualOffline", true).catch(err => {});
        }
      }
    );
  };

  disableManualOffline = isToggle => {
    if (isToggle) {
      setTimeout(() => {
        this.setState({ showOfflineSwitchToggle: false });
        document
          .querySelector(".bo-body-sidebar")
          .classList.remove("anim-sidebar");
        document.querySelector(".App").classList.remove("sidebar-open");
      }, 700);
    }
  };

  setPermissions = () => {
    if (
      this.state.HeaderData &&
      this.state.HeaderData.BusinessId &&
      this.state.HeaderData.BusinessOwnerIdsWithNames &&
      this.state.HeaderData.BusinessOwnerIdsWithNames.length > 0
    ) {
      let activeBusiness = _findArray(
        this.state.HeaderData.BusinessOwnerIdsWithNames,
        "BusinessId",
        this.state.HeaderData.BusinessId
      );
      if (
        activeBusiness &&
        activeBusiness.RolePermissions &&
        activeBusiness.RolePermissions.Permission
      ) {
        let modules = [];
        let subModules = [];
        let features = [];
        let permissions = {};
        let activePermissions = activeBusiness.RolePermissions.Permission;
        activePermissions.forEach(el => {
          modules.push(el.ModuleID);
        });
        activePermissions.forEach(el => {
          subModules.push(el.SubModuleID);
        });
        activePermissions.forEach(el => {
          features.push(el.FeatureID);
        });

        permissions.modules = _uniqueArray(modules, null, "single");
        permissions.subModules = _uniqueArray(subModules, null, "single");
        permissions.features = _uniqueArray(features, null, "single");
        this.setState({ showSidebar: true, permissions });
        return permissions;
      } else {
        let permissions = { modules: [], subModules: [], features: [] };
        this.setState({ showSidebar: true, permissions });
      }
    } else {
      let permissions = { modules: [], subModules: [], features: [] };
      this.setState({ showSidebar: true, permissions });
    }
  };

  hasPermission = (id, type, alertText) => {
    //prevent non business owner users from doing anything in the app if they haven't started day
    let startDay = cookie.load("currentDay");
    if (
      this.state.HeaderData.UserId &&
      (!startDay ||
        (startDay &&
          Number(startDay.userId) !== Number(this.state.HeaderData.UserId))) &&
      this.state.HeaderData.walletAmount &&
      this.state.HeaderData.walletAmount.IsStartDayEnable &&
      this.state.HeaderData.BusinessOwnerIdsWithNames &&
      _findArray(
        this.state.HeaderData.BusinessOwnerIdsWithNames,
        "BusinessId",
        Number(this.state.HeaderData.BusinessId)
      ) &&
      _findArray(
        this.state.HeaderData.BusinessOwnerIdsWithNames,
        "BusinessId",
        Number(this.state.HeaderData.BusinessId)
      ).IsBO !== 1
    ) {
      return false;
    } else {
      if (type === 1) {
        let modules = this.state.permissions.modules;
        if (modules) {
          return modules.includes(id);
          // return false;
        }
        return false;
      } else if (type === 2) {
        let subModules = this.state.permissions.subModules;
        if (subModules) {
          return subModules.includes(id);
          // return false;
        }
        return false;
      } else if (type === 3) {
        let features = this.state.permissions.features;
        if (features) {
          if (features.includes(id)) {
            return true;
          } else {
            if (alertText) {
              this.showAlert(alertText, "error");
            }
            return false;
          }

          // return false;
        } else {
          return false;
        }
      }
    }
  };

  startPushMessagesTimeout = () => {
    this.pushMesssagesTimeout = setTimeout(() => {
      this.getPushMessages();
      this.pushMessagesInterval = setInterval(
        () => this.getPushMessages(),
        3600000
      );
    }, 10000);
  };

  getPushMessages = messages => {
    if (this.state.jsonHeader) {
      if (messages) {
        localForage
          .setItem("pushMessages", messages)
          .then(() => {})
          .catch(err => {});
        this.setState({ pushMessages: messages });
      }
      axios
        .get(`${host_url}dashboard/GetPushNotifications`, this.state.jsonHeader)
        .then(response => {
          if (response.data.StatusCode === 1) {
            let notifications = response.data.Result.PushNotification;
            if (notifications) {
              notifications.forEach(el => {
                el.title = el.notificationheading;
                el.body = el.NotificationMessage;
                el.read = el.IsReadDetail;
                el.deepLink = el.LandingURL;
                el.time = el.Createddate;
                el.messageId = el.Notificationid;
              });

              this.setState({ pushMessages: notifications }, () => {
                localForage
                  .setItem("pushMessages", notifications)
                  .then(() => {})
                  .catch(err => {});
              });
            } else {
              this.setState({ pushMessages: [] });
            }
          }
        })
        .catch(error => {});
    }
  };

  updateUserLocation = userLocation => {
    this.setState({ userLocation });
  };

  persistStorage = async () => {
    if (!navigator.storage) {
      return;
    }
    let persisted = await navigator.storage.persisted();
    if (!persisted) {
      navigator.storage.persist().then(function() {
        if (navigator.serviceWorker && !navigator.serviceWorker.controller) {
          window.location.reload();
        }
      });
    } else {
      if (
        navigator.serviceWorker &&
        !navigator.serviceWorker.controller &&
        window.location.hostname !== "localhost"
      ) {
        window.location.reload();
      }
    }
  };

  getBusinessSettings = () => {
    axios
      .get(`${host_url}common/GetBusinessSetting`, this.state.jsonHeader)
      .then(response => {
        if (response.data.StatusCode === 1) {
          if (
            response.data.Result.SettingMaster &&
            response.data.Result.SettingMaster.length > 0
          ) {
            let allSettings = response.data.Result.SettingMaster;
            const activatedBusinessSettings = [];
            allSettings.forEach(el => {
              if (el.Status === 1) {
                activatedBusinessSettings.push(Number(el.SettingID));
              }
            });
            this.setState({ activatedBusinessSettings }, () => {
              localForage.setItem(
                "activatedBusinessSettings",
                activatedBusinessSettings
              );
            });
          } else {
            this.setState({ activatedBusinessSettings: [] });
            localForage.setItem("activatedBusinessSettings", []);
          }
        }
      })
      .catch(error => {});
  };

  getContactTypes = async () => {
    try {
      const response = await axios(
        `${host_url}addressbook/GetBusinessCustomerTypeMaster `,
        this.state.jsonHeader
      );
      this.setState(
        {
          contactTypes: response.data.Result.CustomerTypeName
        },
        () => {
          this.saveToIndexDb("contactTypes", this.state.contactTypes);
        }
      );
    } catch (error) {
      console.log(error);
    }
  };

  isSettingsActivated = id => {
    return this.state.activatedBusinessSettings.length > 0
      ? this.state.activatedBusinessSettings.includes(id)
      : false;
  };
  addProfileForWebPush = () => {
    setTimeout(() => {
      if (window.clevertap && this.state.SessionData && this.state.HeaderData) {
        if (
          "Notification" in window &&
          Notification.permission &&
          Notification.permission === "granted" &&
          navigator.serviceWorker.controller
        ) {
          window.clevertap.onUserLogin.push({
            Site: {
              Identity: this.state.SessionData.VCUserContentID,
              Name: this.state.HeaderData.BusinessName,
              Email: _insertInString(
                this.state.SessionData.VCLoginID,
                "@",
                `+${this.state.HeaderData.BusinessId}`
              ),
              BusinessId: this.state.HeaderData.BusinessId,
              ContactPerson: this.state.SessionData.VCUserName,
              Phone: this.state.SessionData.VCUserPhone
                ? `+234${this.state.SessionData.VCUserPhone.substr(1)}`
                : ""
            }
          });
          localForage
            .getItem("hasSubscribedForPushNotifications", (err, value) => {
              if (!value) {
                window.clevertap.notifications.push({
                  titleText: "Would you like to receive Push Notifications?",
                  bodyText:
                    "We promise to only send you relevant content and give you updates on your transactions",
                  okButtonText: "Sign me up!",
                  rejectButtonText: "No thanks",
                  okButtonColor: "#f28046",
                  skipDialog: true
                });
              }
              localForage.setItem("hasSubscribedForPushNotifications", true);
            })
            .catch(err => {});
        }
      }
    }, 3000);
  };

  saveToIndexDb = (field, value) => {
    localForage.setItem(field, value);
  };
}

export default withRouter(App);

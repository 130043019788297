// In production, we register a service worker to serve assets from local cache.

// This lets the app load faster on subsequent visits in production, and gives
// it offline capabilities. However, it also means that developers (and users)
// will only see deployed updates on the "N+1" visit to a page, since previously
// cached resources are updated in the background.

// To learn more about the benefits of this model, read https://goo.gl/KwvDNy.
// This link also includes instructions on opting out of this behavior.

// swBroadCastMessage();

const isLocalhost = Boolean(
  window.location.hostname === "localhost" ||
    // [::1] is the IPv6 localhost address.
    window.location.hostname === "[::1]" ||
    // 127.0.0.1/8 is considered localhost for IPv4.
    window.location.hostname.match(
      /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
    )
);

// console.log(process.env.REACT_APP_LOCAL_PRODUCTION, process.env);
let swUrl;
swUrl = `${window.location.origin}/clevertap_sw.js`;

export default function register() {
  if (
    (process.env.NODE_ENV === "production" ||
      process.env.REACT_APP_LOCAL_PRODUCTION) &&
    "serviceWorker" in navigator
  ) {
    // The URL constructor is available in all browsers that support SW.
    const publicUrl = new URL(process.env.PUBLIC_URL, window.location);
    if (publicUrl.origin !== window.location.origin) {
      // Our service worker won't work if PUBLIC_URL is on a different origin
      // from what our page is served on. This might happen if a CDN is used to
      // serve assets; see https://github.com/facebookincubator/create-react-app/issues/2374
      return;
    }

    window.addEventListener("load", () => {
      if (isLocalhost && !process.env.REACT_APP_LOCAL_PRODUCTION) {
        // This is running on localhost. Lets check if a service worker still exists or not.
        checkValidServiceWorker(swUrl);

        // Add some additional logging to localhost, pointing developers to the
        // service worker/PWA documentation.
        navigator.serviceWorker.ready.then(() => {});
      } else {
        // Is not local host. Just register service worker
        registerValidSW(swUrl);
      }
    });
  } else {
    if (
      !process.env.REACT_APP_LOCAL_PRODUCTION &&
      window.location.hostname === "localhost"
    ) {
      fetch(swUrl)
        .then(() => {
          navigator.serviceWorker.ready.then(registration => {
            registration.unregister().then(() => {
              window.location.reload();
            });
          });
        })
        .catch(() => {
          console.log(
            "No internet connection found. App is running in offline mode."
          );
        });
    }
  }
}

function registerValidSW(swUrl) {
  navigator.serviceWorker
    .register(swUrl, { scope: "/" })
    .then(registration => {
      registration.onupdatefound = () => {
        const installingWorker = registration.installing;
        installingWorker.onstatechange = () => {
          if (installingWorker.state === "installed") {
            if (navigator.serviceWorker.controller) {
            } else {
            }
          }
        };
      };

      setInterval(() => {
        registration.update();
      }, 3600000);
    })
    .catch(error => {
      console.error("Error during service worker registration:", error);
      setTimeout(() => {
        registerValidSW(swUrl);
      }, 600000);
    });
}

function checkValidServiceWorker(swUrl) {
  fetch(swUrl)
    .then(response => {
      if (
        response.status === 404 ||
        response.headers.get("content-type").indexOf("javascript") === -1
      ) {
        navigator.serviceWorker.ready.then(registration => {
          registration.unregister().then(() => {
            window.location.reload();
          });
        });
      } else {
        // registerValidSW(swUrl);
      }
    })
    .catch(() => {
      console.log(
        "No internet connection found. App is running in offline mode."
      );
    });
}

export function unregister() {
  if ("serviceWorker" in navigator) {
    navigator.serviceWorker.ready.then(registration => {
      registration.unregister();
    });
  }
}

// function swBroadCastMessage() {
//    //listen to messages from service worker
//    const channel = new BroadcastChannel("sw-messages");
//    channel.addEventListener("message", event => {
//       if (event.data && event.data.action && event.data.action === "reload") {
//          window.location.reload();
//       }
//    });
// }
